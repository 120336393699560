// project import
import navigation from './navigation';
import dev from './dev';
import support from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [navigation, dev, support]
};

export default menuItems;
