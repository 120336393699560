import { forwardRef } from "react"; // prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SRButton
import ButtonRoot from "components/buttons/ButtonRoot";

const SRButton = forwardRef(
  (
    { label, color, variant, size, circular, iconOnly, children, ...rest },
    ref
  ) => (
    <ButtonRoot
      {...rest}
      ref={ref}
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly }}
      style={{ textTransform: "none" }}
    >
      {children || label}
    </ButtonRoot>
  )
);

// Setting default values for the props of SRButton
SRButton.defaultProps = {
  size: "large",
  variant: "gradient",
  color: "primary",
  circular: false,
  iconOnly: false,
  label: "",
};

// Typechecking props for the SRButton
SRButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "default",
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  label: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default SRButton;
