import React from "react";
import {
  Box,
  Grid,
  Typography,
} from "../../../../node_modules/@mui/material/index";

export const SimpleContainer = (props) => {
  const { children, label } = props;

  return (
    <Grid
      item
      container
      xs={12}
      sx={{ mt: 2.25, border: 1, borderColor: "divider" }}
    >
      <Grid item xs={12}>
        <Typography>{label}</Typography>
      </Grid>

      {React.Children.map(children, (child, i) => (
        <Grid item xs={12} key={label + i} sx={{ mt: 2.25 }}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};
