import { groupBy } from "helpers/Service";
import {
  Box,
  Grid,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import moment from "../../../../node_modules/moment/moment";
import DynamicDialog from "components/dialogs/Dialog";
import { getDialogButton } from "helpers/General";
import { useState } from "react";
import SummaryWidget from "components/widgets/summaryWidget";
import SummaryBox from "../SummaryBox";
import { validateBuilderChecklists } from "helpers/ValidateBuilderChecklistDetails";
import { BUILDER_DEFICIENCY } from "constants/api";

export default function BuilderDeficienciesSummarySquare(props) {
  const { homes, dates } = props;

  const [tableDialog, setTableDialog] = useState(false);
  const [homeDetails, sethomeDetails] = useState([]);
  const [builderClicked, setbuilderClicked] = useState("");
  //homes that are closed are those whose closing date is before now
  //homes that are sucessfull are homes which are in status CX complete
  //otherwise closed with errors
  const resetDialogs = () => {
    setTableDialog(false);
  };

  var closeButton = getDialogButton("Close", resetDialogs);
  let localhomes = homes.map((h) => {
    return { ...h };
  });
  const goodHomes = localhomes.filter(
    (x) => {
      const issues = validateBuilderChecklists(x.bimRecord?.bimChecklists);

      if (issues && issues.length > 0) {
        return false;
      }
      return true;
    },
    // x.srCalendarEvents[0].calendarColorId === 3 &&
  );

  localhomes = localhomes.map((h) => {
    if (!goodHomes.includes(h)) {
      h.alertMessage = "Builder Issue with home";
    }
    return h;
  });

  const BuilderIsses = [];
  const allhomesByBuilders = groupBy(localhomes, (x) => x.builder?.companyName);

  const goodHomesByBuilder = groupBy(goodHomes, (x) => x.builder?.companyName);

  const emailReport = (email) => {
    const callAddress = `${BUILDER_DEFICIENCY}?startYearMonthDay=${moment(
      dates[0],
    ).format("YYYY,MM,DD")}&endYearMonthDay=${moment().format(
      "YYYY,MM,DD",
    )}&calId=sterlingranchlab.srco@gmail.com&shouldEmailAxiosTechs=false&failedCategory=all&targetBuilder=all&overrideEmail=${email}`;
    fetch(callAddress, {
      method: "GET",
    });

    console.log(
      "back backend api call for builder deficiency report with dates",
      dates,
      "and email",
      email,
    );
  };

  return (
    <SummaryBox
      color={"#f0f8fa"}
      reportFunction={emailReport}
      reportName="builder deficiency"
    >
      <Grid container sx={{ padding: "1em" }}>
        <Grid item xs={12}>
          <Typography variant="h4">{`Builder Deficiencies`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle4"
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              sethomeDetails(localhomes);
              setTableDialog(true);
              setbuilderClicked("All Closed Homes");
            }}
          >{`Total builder Issues - ${localhomes.length - goodHomes.length}/${
            localhomes.length
          }`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{`Deficiency Snapshot`}</Typography>
        </Grid>
        {Array.from(allhomesByBuilders).map((row) => {
          return (
            <Grid
              item
              container
              xs={12}
              key={row[0]}
              onClick={() => {
                sethomeDetails(row[1]);
                setTableDialog(true);
                setbuilderClicked(row[0]);
              }}
              sx={{ cursor: "pointer", textDecoration: "underline" }}
            >
              <Grid item xs={6}>
                {`${row[0]}: `}
              </Grid>
              <Grid item xs={3}>
                {`${
                  goodHomesByBuilder.get(row[0])
                    ? row[1].length - goodHomesByBuilder.get(row[0]).length
                    : row[1].length
                } / ${row[1].length}`}
              </Grid>

              <Grid item xs={3}>
                {`${(
                  ((goodHomesByBuilder.get(row[0])
                    ? row[1].length - goodHomesByBuilder.get(row[0]).length
                    : row[1].length) /
                    row[1].length) *
                  100
                ).toFixed(0)}%`}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <DynamicDialog
        maxWidth="lg"
        fullWidth
        buttons={[closeButton]}
        open={tableDialog}
        title={builderClicked}
        //children={<HomeDetailsCard {...homeDetails} />}
        children={<SummaryWidget homes={homeDetails} />}
      />
    </SummaryBox>
  );
}
