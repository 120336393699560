import * as React from 'react';

import { Grid, Typography, Card,CardContent, CardActions, Button, Box } from '@mui/material';
import { getGoogleCalendarTitle, getGoogleCalendarColor, getBimChecklistStatusText, getBimChecklistStatusIcons, getCopperStatusIcons, getGoogleCalendarDate, getFreshdeskStatus } from 'helpers/Service';


export default function HomeSummaryCard(props) {
  //console.log(props.children);
    return (
    <Box sx={{ minWidth: 275 }}>
      <Card  variant="outlined">
      <React.Fragment>
    <CardContent >
      <Grid style={{minHeight:"250px"}} container direction='column' rowSpacing={1} columnSpacing={2.75}>
      
      <Grid style={{paddingTop:'20px', paddingLeft:'20px'}} container direction='column'>
      <Grid item xs={12}>
      {getGoogleCalendarDate(props)}
      </Grid>

      <Grid item xs={12}>
      <Grid style={{ minHeight:"75px"}} container direction='row'>
        <Grid item xs={11}>
        {getGoogleCalendarTitle(props)}
        </Grid>

        <Grid item xs={1}>
        {getGoogleCalendarColor(props)}
        </Grid>
        </Grid>
      </Grid>

      </Grid>

      <Grid style={{marginBottom:"10px"}} item xs={12} sx={{ mb: -2.25 }}>
      <Typography variant="h5" component="div">
       {"BIM Checklist "}
      </Typography>
      <Typography variant="h5" component="div">
       {getBimChecklistStatusIcons(props, "CLA")}
       {getBimChecklistStatusIcons(props, "CAB")}
      </Typography>
      </Grid>
      <Grid style={{marginBottom:"10px"}} item xs={12} sx={{ mb: -2.25 }}>
      <Typography variant="h5" component="div">
        {"Copper Status "}     
      </Typography>
      <Typography variant="h5" component="div">
        {getCopperStatusIcons(props, "gas")}
        {getCopperStatusIcons(props, "power_net")}
        {getCopperStatusIcons(props, "outdoor_water")}
        {getCopperStatusIcons(props, "indoor_water")}
        {/*getCopperStatusIcons(props, "gateways")*/}        
      </Typography>
      </Grid>
      <Grid style={{marginBottom:"10px"}} item xs={12} sx={{ mb: -2.25 }}>
      <Typography variant="h5" component="div">
        {"Freshdesk "}
      </Typography>
      <Typography variant="h5" component="div">
        {getFreshdeskStatus(props)}
      </Typography>
      </Grid>
      </Grid>

    </CardContent>
    <CardActions>
    <Button size="small" onClick={() => props.showCardDetails(props)}>Show Details</Button>    
    </CardActions>
  </React.Fragment>
      </Card>
    </Box>
  );
}
