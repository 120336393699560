import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import SRInput from "components/inputs/index";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

import { Box, Button, Typography } from "@mui/material";
import SRButton from "components/buttons/index";
import { useTheme } from "@mui/material/styles";

function RenderComponent({ type, ...rest }) {
  switch (type) {
    case "input":
      return <SRInput {...rest} fullWidth />;

    default:
      break;
  }
}
RenderComponent.defaultProps = {
  type: "",
};

// Typechecking props for the CFInput
RenderComponent.propTypes = {
  type: PropTypes.string,
};

function ServerDialog({
  title,
  showPopup,
  message,
  updatePopup,
  closeAction,
  closable,
  ...rest
}) {
  let theme = useTheme();
  let closeModel = () => updatePopup({ showPopup: false });
  if (closeAction) {
    closeModel = () => closeAction();
  }
  const { children } = rest;

  return (
    <Modal
      open={showPopup}
      onClose={closable ? closeModel : undefined}
      sx={{ display: "grid", placeItems: "center" }}
    >
      <Slide direction="down" in={showPopup} timeout={500}>
        <Box
          position="relative"
          width="500px"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          shadow="xl"
          sx={{ backgroundColor: theme.palette.common.white }}
        >
          <Box
            display="flex"
            algin="center"
            justifyContent="space-between"
            p={2}
          >
            <Typography variant="h5">{title}</Typography>
            {closable && (
              <CloseIcon
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={closeModel}
              />
            )}
          </Box>
          <Divider sx={{ my: 0 }} />
          {children && children}
          {!children && (
            <Box p={2}>
              <Typography variant="body2" fontWeight="regular">
                {message}
              </Typography>
              {rest && rest.component && RenderComponent(rest.component)}
            </Box>
          )}
          <Divider sx={{ my: 0 }} />
          <Box display="flex" justifyContent="space-between" p={1.5}>
            {closable && (
              <SRButton
                variant="contained"
                color="dark"
                onClick={closeModel}
                sx={{ margin: "1em" }}
              >
                <span style={{ color: "white" }}>Close</span>
              </SRButton>
            )}

            {rest && rest.button1 && (
              <Button {...rest.button1} sx={{ margin: "1em" }}>
                {rest.button1.text}
              </Button>
            )}
            {rest && rest.button2 && (
              <Button {...rest.button2} sx={{ margin: "1em" }}>
                {rest.button2.text}
              </Button>
            )}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
}

// Setting default values for the props of CFInput
ServerDialog.defaultProps = {
  title: "COMET-Farm Popup",
  showPopup: false,
  message: "",
  updatePopup: "",
  closeAction: null,
  closable: true,
};

// Typechecking props for the CFInput
ServerDialog.propTypes = {
  title: PropTypes.string,
  showPopup: PropTypes.bool,
  message: PropTypes.string,
  updatePopup: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  closeAction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  closable: PropTypes.bool,
};

export default ServerDialog;
