// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { useDispatch, useSelector } from "react-redux";
import LoadingMask from "./components/LoadingMask";
import { loggedIn, updateUserData } from "./utils/auth";
import ServerDialog from "components/dialogs/ServerDialog";
import { updatePopup } from "store/reducers/popup";
import { fetchUser } from "helpers/SrApiCalls";
import { useEffect } from "react";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const loading = useSelector((state) => state.mask.loading);
  const loadingMessage = useSelector((state) => state.mask.message);

  const genericPopupState = useSelector((state) => state.popup);
  const userLoggedIn = loggedIn();

  const dispatch = useDispatch();

  const role = useSelector((state) => state.user.role);

  const updatePopupGlobally = (e) => {
    dispatch(updatePopup(e));
  };

  return (
    <div>
      {loading ? <LoadingMask message={loadingMessage} /> : <div />}
      {genericPopupState && genericPopupState.showPopup && (
        <ServerDialog
          {...genericPopupState}
          updatePopup={(e) => updatePopupGlobally(e)}
        />
      )}
      <ThemeCustomization>
        <ScrollTop>
          <Routes authenticated={userLoggedIn} role={role} />
        </ScrollTop>
      </ThemeCustomization>
    </div>
  );
};

export default App;
