import * as React from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import moment from "moment";

export default function CustomDateRange(props) {
  let { dateRange } = props;
  if (!dateRange || dateRange.length != 2) {
    dateRange = [
      moment().subtract(7, "days").format(),
      moment().add(7, "days").format(),
    ];
  }

  const [value, setValue] = React.useState(dateRange);

  return (
    <Stack spacing={3}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{ start: "Start Date", end: "End Date" }}
      >
        <DesktopDateRangePicker
          value={value}
          start
          onChange={(newValue) => {
            const formattedValue = [];
            for (let i = 0; i < newValue.length; i += 1) {
              if (newValue[i]) formattedValue.push(newValue[i].format());
            }

            setValue(formattedValue);
            if (props)
              if (props.updateRange != null) props.updateRange(formattedValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} style={{ backgroundColor: "white" }} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} style={{ backgroundColor: "white" }} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
}
