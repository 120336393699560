import { useSelector, useDispatch } from "react-redux";
import { Typography, Button } from "@mui/material";
import { getStatusFromConnection } from "utils/parser";
import { Box, Grid } from "../../../../../node_modules/@mui/material/index";
import { unlogin } from "utils/auth";
import { getUserRole } from "helpers/General";
import moment from "../../../../../node_modules/moment/moment";

// ==============================|| HEADER - CONTENT ||============================== //

const userStatusString = (userStatus) => (
  <div>
    {userStatus["Closing Calendar Events Updated"] && (
      <div>
        <strong>Calendar Updated: </strong>{" "}
        {moment(
          userStatus["Closing Calendar Events Updated"],
          "YYYY-MM-DDTHH:mm:ss",
        ).format("MM-DD-YYYY-HH:mm:ss")}
      </div>
    )}

    {/*userStatus["Bim Homes Updated"] && (
      <div>
        <strong>Homelist Updated: </strong>{" "}
        {moment(userStatus["Bim Homes Updated"], "YYYY-MM-DDTHH:mm:ss").format(
          "MM-DD-YYYY-HH:mm:ss",
        )}
      </div>
    )*/}

    {userStatus["Checklist Details Updated"] && (
      <div>
        <strong>BIM360 Details Updated: </strong>{" "}
        {moment(
          userStatus["Checklist Details Updated"],
          "YYYY-MM-DDTHH:mm:ss",
        ).format("MM-DD-YYYY-HH:mm:ss")}
      </div>
    )}
  </div>
);

const HeaderContent = () => {
  const connection = useSelector((state) => state.connection);
  const user = useSelector((state) => state.user);

  const getConnectionSuccess = () => {
    //console.log(connection);
    return (
      connection.bimAuthResponse.success &&
      connection.copperAuthResponse.success &&
      connection.freshdeskAuthResponse.success &&
      connection.googleAuthResponse.success
    );
  };
  //console.log("user", user);
  let userStatus = {};
  if (user && user?.srApplicationEventLogs) {
    userStatus = Object.assign(
      {},
      ...user?.srApplicationEventLogs?.map(({ eventTitle, dateTime }) => ({
        [eventTitle]: dateTime,
      })),
    );
  }

  //console.log("userStatus", userStatus);

  let overallConnectionStatus = getConnectionSuccess();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ paddingLeft: "1em" }}
    >
      <Grid item xs={5}>
        <Typography
          style={{
            paddingRight: "2px",
            textTransform: "capitalize",
            marginBottom: "0px",
          }}
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {`welcome back, ${user.first}!`}
        </Typography>
        <Typography
          style={{ paddingRight: "2px" }}
          color="textPrimary"
          gutterBottom
          variant="subtitle"
        >
          {`Here's what's going on in Sterling Ranch`}
        </Typography>
      </Grid>

      <Grid item xs={5}>
        <Box sx={{ display: "flex" }}>
          <Typography
            style={{ paddingRight: "2px" }}
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {" Status : "}
          </Typography>
          <Typography
            color={overallConnectionStatus ? "primary" : "primary"}
            gutterBottom
            variant="h5"
          >
            {overallConnectionStatus
              ? "All APIs Connected"
              : "All APIs Connected"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", fontSize: ".75em" }}>
          {userStatusString(userStatus)}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box
          sx={{
            position: "absolute",
            display: "grid",

            alignItems: "center",
          }}
        >
          <Button variant="contained" onClick={unlogin}>
            Sign Out
          </Button>
          <Typography
            variant="subtitle"
            style={{ paddingRight: "2px", textTransform: "capitalize" }}
          >
            {`you have ${getUserRole(user.role)} permissions`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeaderContent;
