export const LOGIN_E = {key:"email", label:"Email"};
export const LOGIN_P = {key:"password", label:"Password"};

export const REGISTER_F_N = {key:"first", label:"First Name"};
export const REGISTER_L_N = {key:"last", label:"Last Name"};
export const REGISTER_E = {key:"email", label:"Email"};
export const REGISTER_P = {key:"password", label:"Password"};
export const REGISTER_C_P = {key:"confirm", label:"Confirm Password"};
export const REGISTER_COMP = {key:"company", label:"Company"};

export const CONFIRM_E = {key:"email", label:"Email"};
export const COMFIRM_C = {key:"code", label:"Code"};