import { Button } from "@mui/material";
export const LOGIN = ``;
export const RESET = ``;
export const REGISTER = ``;
export const CONFIRM = ``;
export const DASHBOARD = `dashboard`;
export const WIDGET = `widget`;
export const ADMIN = "admin";
export const SCHEDULE = `schedule`;
export const QUERY = `query`;
export const HOMES = `homes`;
export const BUILDERS = `builders`;
export const DEVPAGES = `dev`;

export const getUserRole = (roleLetter) => {
  switch (roleLetter) {
    case "a":
      return "Admin";
    case "n":
      return "New User";
    default:
      return roleLetter;
  }
};

/*
  a - admin all areas
  cx - cx area TDB
  cab - access to cab portal, or location where we manage homes
  bim - alignment portal TBD
  builder - Area for builders to enter their home information
 */
export const getPageAccess = (id) => {
  var accessList = [];

  switch (id) {
    case SCHEDULE:
      accessList.push("cx");
      accessList.push("a");

      break;
    case QUERY:
      accessList.push("cx");
      accessList.push("cab");
      accessList.push("bim");
      accessList.push("builder");
      accessList.push("a");
      break;
    case HOMES:
      accessList.push("cx");
      accessList.push("cab");
      accessList.push("bim");
      accessList.push("builder");
      accessList.push("a");
      break;
    case BUILDERS:
      accessList.push("cx");
      accessList.push("cab");
      accessList.push("bim");
      accessList.push("builder");
      accessList.push("a");
      break;
    case WIDGET:
      accessList.push("a");

    case DASHBOARD:
      accessList.push("cx");
      accessList.push("cab");
      accessList.push("bim");
      accessList.push("builder");
      accessList.push("a");
      accessList.push("n");

    case DEVPAGES:
      accessList.push("a");

    case ADMIN:
      accessList.push("a");

      break;
    default:
    // code block
  }

  return accessList;
};

export const groupByKeyAsObject = function (xs, key) {
  let grouped = xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
  return grouped;
};

export const groupByKey = function (xs, key) {
  let grouped = xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
  return Object.values(grouped);
};

export const getDialogButton = (label, onClick) => {
  return (
    <Button variant="contained" color="primary" onClick={onClick}>
      {label}
    </Button>
  );
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};
