// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors) => {
    const { blue, red, gold, cyan, green, grey, yellow, purple, magenta, orange } = colors;
    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16]
    };
    const contrastText = '#fff';

    return {
        primary: {
            lighter: blue[0],
            100: blue[1],
            200: blue[2],
            light: blue[3],
            400: blue[4],
            main: blue[5],
            dark: blue[6],
            700: blue[7],
            darker: blue[8],
            900: blue[9],
            contrastText
        },
        primaryLight: {
            lighter: blue[0],
            100: blue[1],
            200: blue[1],
            light: blue[1],
            400: blue[1],
            main: blue[1],
            dark: blue[6],
            700: blue[7],
            darker: blue[8],
            900: blue[9],
            contrastText
        },
        secondary: {
            lighter: greyColors[100],
            100: greyColors[100],
            200: greyColors[200],
            light: greyColors[300],
            400: greyColors[400],
            main: greyColors[500],
            600: greyColors[600],
            dark: greyColors[700],
            800: greyColors[800],
            darker: greyColors[900],
            A100: greyColors[0],
            A200: greyColors.A400,
            A300: greyColors.A700,
            contrastText: greyColors[0]
        },
        error: {
            lighter: red[0],
            light: red[2],
            main: red[4],
            dark: red[7],
            darker: red[9],
            contrastText
        },
        warning: {
            lighter: gold[0],
            light: gold[3],
            main: gold[5],
            dark: gold[7],
            darker: gold[9],
            contrastText: greyColors[100]
        },
        info: {
            lighter: cyan[0],
            light: cyan[3],
            main: cyan[5],
            dark: cyan[7],
            darker: cyan[9],
            contrastText
        },
        Peacock: {
            lighter: cyan[0],
            light: cyan[3],
            main: cyan[5],
            dark: cyan[7],
            darker: cyan[9],
            contrastText
        },
        PeacockLight: {
            lighter: cyan[3],
            light: cyan[3],
            main: cyan[3],
            dark: cyan[3],
            darker: cyan[3],
            contrastText
        },
        success: {
            lighter: green[0],
            light: green[3],
            main: green[5],
            dark: green[7],
            darker: green[9],
            contrastText
        },
        grey: greyColors,
        yellow: 
        {
            lighter: yellow[3],
            light: yellow[5],
            main: yellow[6],
            dark: yellow[8],
            darker: yellow[10],
            contrastText
        },
        Banana: 
        {
            lighter: yellow[3],
            light: yellow[5],
            main: yellow[6],
            dark: yellow[8],
            darker: yellow[10],
            contrastText
        },
        BananaLight: 
        {
            lighter: yellow[3],
            light: yellow[3],
            main: yellow[3],
            dark: yellow[8],
            darker: yellow[10],
            contrastText
        },
        Grape: 
        {
            lighter: purple[0],
            light: purple[3],
            main: purple[5],
            dark: purple[7],
            darker: purple[9],
            contrastText
        },
        GrapeLight: 
        {
            lighter: purple[3],
            light: purple[3],
            main: purple[3],
            dark: purple[7],
            darker: purple[9],
            contrastText
        },
        purple: 
        {
            lighter: purple[0],
            light: purple[3],
            main: purple[5],
            dark: purple[7],
            darker: purple[9],
            contrastText
        },
        magenta: 
        {
            lighter: magenta[0],
            light: magenta[3],
            main: magenta[5],
            dark: magenta[7],
            darker: magenta[9],
            contrastText
        },
        Graphite: 
        {
            lighter: grey[0],
            light: grey[3],
            main: grey[5],
            dark: grey[7],
            darker: grey[9],
            contrastText
        },
        GraphiteLight: 
        {
            lighter: grey[3],
            light: grey[3],
            main: grey[3],
            dark: grey[7],
            darker: grey[9],
            contrastText
        },
        Red: {
            lighter: red[0],
            light: red[2],
            main: red[7],
            dark: red[8],
            darker: red[9],
            contrastText
        },
        Tomato: {
            lighter: red[0],
            light: red[2],
            main: red[7],
            dark: red[8],
            darker: red[9],
            contrastText
        },
        TomatoLight: {
            lighter: red[2],
            light: red[2],
            main: red[2],
            dark: red[8],
            darker: red[9],
            contrastText
        },
        Tangerine: {
            lighter: orange[0],
            light: orange[2],
            main: orange[7],
            dark: orange[8],
            darker: orange[9],
            contrastText
        },
        TangerineLight: {
            lighter: orange[2],
            light: orange[2],
            main: orange[2],
            dark: orange[8],
            darker: orange[9],
            contrastText
        },
        Sage: {
            lighter: green[0],
            light: green[3],
            main: green[5],
            dark: green[7],
            darker: green[9],
            contrastText
        },
        SageLight: {
            lighter: green[3],
            light: green[3],
            main: green[3],
            dark: green[7],
            darker: green[9],
            contrastText
        },
        Blueberry: {
            lighter: blue[0],
            light: blue[3],
            main: blue[5],
            dark: blue[7],
            darker: blue[9],
            contrastText
        },
        BlueberryLight: {
            lighter: blue[3],
            light: blue[3],
            main: blue[3],
            dark: blue[7],
            darker: blue[9],
            contrastText
        },
        Lavender: {
            lighter: blue[0],
            light: blue[0],
            main: blue[3],
            dark: blue[5],
            darker: blue[7],
            contrastText
        },
        LavenderLight: {
            lighter: blue[0],
            light: blue[0],
            main: blue[0],
            dark: blue[5],
            darker: blue[7],
            contrastText
        },
        Flamingo: 
        {
            lighter: magenta[0],
            light: magenta[3],
            main: magenta[5],
            dark: magenta[7],
            darker: magenta[9],
            contrastText
        },
        FlamingoLight: 
        {
            lighter: magenta[0],
            light: magenta[3],
            main: magenta[3],
            dark: magenta[7],
            darker: magenta[9],
            contrastText
        }

    };
};

export default Theme;
