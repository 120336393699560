import {
  Box,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import moment from "../../../../node_modules/moment/moment";
import DynamicDialog from "components/dialogs/Dialog";
import { getDialogButton } from "helpers/General";
import { useEffect, useState } from "react";
import SummaryWidget from "components/widgets/summaryWidget";
import SummaryBox from "../SummaryBox";
import { VerticalAlignCenter } from "../../../../node_modules/@mui/icons-material/index";
import { VALIDATE_CLA } from "constants/api";

const PossibleDaysOffline = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
export default function UtilitiesSummarySquare(props) {
  const { homes, dates } = props;

  const [tableDialog, setTableDialog] = useState(false);
  const [homeDetails, sethomeDetails] = useState([]);
  const [builderClicked, setbuilderClicked] = useState("");
  const [daysOffline, setdaysOffline] = useState(2);
  const [disconnectedForXDays, setdisconnectedForXDays] = useState([]);

  const handleChange = (newVal) => {
    setdaysOffline(newVal.target.value);
  };

  //homes that are closed are those whose closing date is before now
  //homes that are sucessfull are homes which are in status CX complete
  //otherwise closed with errors
  const resetDialogs = () => {
    setTableDialog(false);
  };

  var closeButton = getDialogButton("Close", resetDialogs);

  let localHomes = homes.map((h) => {
    return { ...h };
  });

  const noPremise = localHomes.filter(
    (x) =>
      x.copperMeters.length === 0 || x.copperMeters.every((m) => !m.premise_id),
  );

  const hasPremise = localHomes.filter(
    (x) =>
      x.copperMeters.length > 0 && x.copperMeters.some((m) => m.premise_id),
  );

  const WaterOff = hasPremise.filter((x) => {
    const meters = x.copperMeters.filter((m) => m.type.indexOf("water") > -1);
    return (
      !meters ||
      meters.length === 0 ||
      meters.some((m) => m.state !== "connected")
    );
  });

  const ElectricOff = hasPremise.filter((x) => {
    const meters = x.copperMeters.filter((m) => m.type.indexOf("power") > -1);
    return (
      !meters ||
      meters.length === 0 ||
      meters.some((m) => m.state !== "connected")
    );
  });

  const GasOff = hasPremise.filter((x) => {
    const meters = x.copperMeters.filter((m) => m.type.indexOf("gas") > -1);
    return (
      !meters ||
      meters.length === 0 ||
      meters.some((m) => m.state !== "connected")
    );
  });
  /*  //placeholder until the specific needed checklist question is in 8-26-2024

  const checklistNotYes = localHomes.filter((x) => {
    const utilChecklist = x?.bimRecord?.bimChecklists.filter(
      (y) => y.name.indexOf("Utility") > -1,
    )[0];
    if (!utilChecklist || !utilChecklist.bimChecklistDetail) {
      return true;
    }

    const question = utilChecklist.bimChecklistDetail.checklist_items.filter(
      (y) =>
        y.question_text ===
        "Premise Created and All Meters Connected to Copper App and Wi-Fi?",
    )[0];
    console.log("isbad?", question);
    return (
      !question ||
      !question.custom_field_values ||
      !question.custom_field_values.value ||
      !question.custom_field_values.value === "Yes"
    );
  });
*/
  useEffect(() => {
    const xDaysAgo = new moment().add(-daysOffline, "d");
    setdisconnectedForXDays([
      ...noPremise,
      ...hasPremise.filter((x) => {
        let show = true;
        for (var i = 0; i < x.copperMeters.length; i += 1) {
          if (x.copperMeters[i].state != "connected") {
            for (let j = 0; j < x.copperMeters[i].valuesByTime.length; j += 1) {
              const eventStatus = x.copperMeters[i].valuesByTime[j].value;
              let eventIsActive =
                eventStatus === "connected" ||
                eventStatus === "active" ||
                eventStatus === "passive" ||
                eventStatus === "learning" ||
                eventStatus === "scanning";

              if (
                eventIsActive &&
                moment(
                  x.copperMeters[i].valuesByTime[j].date,
                  "YYYY-MM-DDTHH:mm:ss",
                ).isAfter(xDaysAgo)
              ) {
                return false;
              }
            }
          } else {
            return false;
          }
        }
        return show;
      }),
    ]);
  }, [daysOffline, localHomes.length]);

  localHomes = localHomes.map((h) => {
    const alerts = [];

    if (noPremise.includes(h)) {
      alerts.push("Home missing premise");
    }
    if (WaterOff.includes(h)) {
      alerts.push("Water Meter Missing");
    }
    if (ElectricOff.includes(h)) {
      alerts.push("Electric Meter Missing");
    }
    if (GasOff.includes(h)) {
      alerts.push("Gas Meter Missing");
    }
    h.alertMessage = alerts.join("\n");
    return h;
  });

  const emailReport = (email) => {
    const callAddress = `${VALIDATE_CLA}?shouldEmailAxiosTechs=false&startYearMonthDay=${moment(
      dates[0],
    ).format("YYYY,MM,DD")}&endYearMonthDay=${moment().format(
      "YYYY,MM,DD",
    )}&cronMonths=-1&validationType=other&overrideEmail=${email}`;
    fetch(callAddress, {
      method: "GET",
    });

    console.log(
      "do backend api call for utility report with dates",
      dates,
      "and email",
      email,
    );
  };

  const PercentageNoProblem =
    localHomes.length !== 0 ? (hasPremise.length / localHomes.length) * 100 : 0;
  return (
    <SummaryBox
      color={"#eefdee"}
      reportFunction={emailReport}
      reportName="utility"
    >
      <Grid container sx={{ padding: "1em" }}>
        <Grid item xs={12}>
          <Typography variant="h4">{`Utilities ${PercentageNoProblem.toFixed()}%`}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            sethomeDetails(localHomes);
            setTableDialog(true);
            setbuilderClicked("All Closed Homes");
          }}
        >
          <Typography variant="subtitle4">{`${hasPremise.length}/${localHomes.length} Connected Premise(s)`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{`Utility Report`}</Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          onClick={() => {
            sethomeDetails(noPremise);
            setTableDialog(true);
            setbuilderClicked("No Premise");
          }}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <Grid item xs={8}>
            No Premise
          </Grid>
          <Grid item xs={4}>
            {noPremise.length}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          onClick={() => {
            sethomeDetails(WaterOff);
            setTableDialog(true);
            setbuilderClicked("Water Meter Offline");
          }}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <Grid item xs={8}>
            Water Meter Offline
          </Grid>
          <Grid item xs={4}>
            {WaterOff.length}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          onClick={() => {
            sethomeDetails(ElectricOff);
            setTableDialog(true);
            setbuilderClicked("Electric Meter Offline");
          }}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <Grid item xs={8}>
            Electric Meter Offline
          </Grid>
          <Grid item xs={4}>
            {ElectricOff.length}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          onClick={() => {
            sethomeDetails(GasOff);
            setTableDialog(true);
            setbuilderClicked("Gas Meter Offline");
          }}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <Grid item xs={8}>
            Gas Meter Offline
          </Grid>
          <Grid item xs={4}>
            {GasOff.length}
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          onClick={(event) => {
            if (event.target.tagName !== "LI") {
              sethomeDetails(disconnectedForXDays);
              setTableDialog(true);
              setbuilderClicked(
                `Premise Offline for at least ${daysOffline} days`,
              );
            }
          }}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            alignItems: "center",
          }}
        >
          <Grid item xs={8}>
            Premise Disconnected for{" "}
            <Select
              id="premiseOfflineDays"
              value={daysOffline}
              onChange={handleChange}
              style={{ padding: 0 }}
            >
              {PossibleDaysOffline.map((x) => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>{" "}
            Days
          </Grid>
          <Grid item xs={4}>
            {disconnectedForXDays.length}
          </Grid>
        </Grid>

        {/*  //placeholder until the specific needed checklist question is in 8-26-2024
        <Grid
          item
          container
          xs={12}
          onClick={(event) => {
            sethomeDetails(checklistNotYes);
            setTableDialog(true);
            setbuilderClicked(`"Premise Created" checklist item is not "Yes"`);
          }}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            alignItems: "center",
          }}
        >
          <Grid item xs={8}>
            "Premise Created" checklist item is not "Yes"
          </Grid>
          <Grid item xs={4}>
            {checklistNotYes.length}
          </Grid>
        </Grid>
        */}
      </Grid>

      <DynamicDialog
        maxWidth="lg"
        fullWidth
        buttons={[closeButton]}
        open={tableDialog}
        title={builderClicked}
        //children={<HomeDetailsCard {...homeDetails} />}
        children={<SummaryWidget homes={homeDetails} />}
      />
    </SummaryBox>
  );
}
