import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {role:'b'};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, action) {
      state.role = action.payload.role;
    },
    receiveUser(state, action) {
      return action.payload;
    },
  },
});

export const { updateUser, receiveUser } = user.actions;

export default user.reducer;
