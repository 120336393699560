import { Grid, Typography } from "@mui/material";
import TableHeaderDatePicker from "components/cards/dashboard/TableHeaderDatePicker";
import HomeDetailsCard from "components/cards/home/HomeDetailsCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "../../../node_modules/moment/moment";
import {
  getDateRange,
  COPPER_DATA_MISSING_REVISIT,
  CX_PARTIAL_COMPLETE,
  NETWORK_ONT_MISSING_WG,
  BUILDER_ISSUES,
  CX_COMPLETE,
  HARDWARE_PREPPED_WAITING_ON_BUILDER,
  HS_BOX_CONFIGURED,
  IN_REVIEW_AXIOS,
  LT_GREEN_MISSINGPAIR_RACHIO,
  NO_VISIT,
  KeyToColor,
  defaultDate,
} from "helpers/Service";
import CustomDateRange from "components/date/CustomDateRange";
import HomeStatusPieChart from "components/charts/HomeStatusPieChart";
import { dispatch } from "store/index";
import { updateLoading } from "store/reducers/mask";
import { fetchHomeData } from "helpers/SrApiCalls";
import HomesWidget from "components/widgets/homesWidget";
import { Box } from "../../../node_modules/@mui/material/index";
import TabViewContainer from "components/containers/tabViewContainer";

import SummaryWidget from "components/widgets/summaryWidget";
import { WIDGET_MASK_ID } from "constants/api";

const Snapshot = () => {
  const [homes, setHomes] = useState([]);

  const [dateRange, setDateRange] = useState(defaultDate());

  const fetchHomes = async () => {
    dispatch(updateLoading({ loading: true, fromId: WIDGET_MASK_ID }));
    var newHomes = await fetchHomeData(dateRange);

    if (newHomes) {
      setHomes(newHomes.allHomes);
    } else {
      console.log("Fetching categories failed");
    }

    dispatch(updateLoading({ loading: false, fromId: WIDGET_MASK_ID }));
  };

  useEffect(() => {
    fetchHomes();
  }, [dateRange]);

  const user = useSelector((state) => state.user);

  if (user.role === "n") {
    return (
      <Grid
        container
        direction="row"
        rowSpacing={4.5}
        columnSpacing={2.75}
        padding={2}
        backgroundColor="#F2F2F2"
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            You must be confirmed by a system admin before you can use this
            tool. After confirmation, please log back in to begin.
          </Typography>
        </Grid>
        <Grid item contianer xs={12}>
          <Grid item contianer xs={6}>
            {" "}
            <Typography variant="h5">Current user:</Typography>
          </Grid>{" "}
          <Grid item contianer xs={6}>
            {" "}
            <Typography variant="h5"> {user.email}</Typography>
          </Grid>
        </Grid>
        <Grid item contianer xs={12}>
          <Grid item md={1}>
            {" "}
            <Typography variant="h5">Status:</Typography>
          </Grid>{" "}
          <Grid item md={1}>
            {" "}
            <Typography variant="h5"> New User</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Snapshot: {} </Typography>
        </Grid>
        {/*     <Grid item xs={12} sx={{ paddingTop: "1em" }}>
          <CustomDateRange updateRange={setDateRange} dateRange={dateRange} />
        </Grid>
*/}
        <Grid item xs={12}>
          {homes && homes.length > 0 && (
            <Box>
              <HomeStatusPieChart
                // homes={homes}
                homeCategories={[
                  NO_VISIT,
                  HS_BOX_CONFIGURED,
                  HARDWARE_PREPPED_WAITING_ON_BUILDER,
                  BUILDER_ISSUES,
                  LT_GREEN_MISSINGPAIR_RACHIO,
                  IN_REVIEW_AXIOS,
                  CX_PARTIAL_COMPLETE,
                  NETWORK_ONT_MISSING_WG,
                  COPPER_DATA_MISSING_REVISIT,
                  CX_COMPLETE,
                ]}
              />
              <Box>
                <TabViewContainer>
                  <HomesWidget
                    // homes={homes}
                    label="home cards"
                  />
                  <SummaryWidget
                    // homes={homes}
                    calendarType="closing"
                    label="closing summary"
                  />
                  <SummaryWidget
                    // homes={homes}
                    calendarType="closing"
                    label="homes with issues"
                    filter={[
                      KeyToColor[NETWORK_ONT_MISSING_WG],
                      KeyToColor[HARDWARE_PREPPED_WAITING_ON_BUILDER],
                      KeyToColor[BUILDER_ISSUES],
                      KeyToColor[LT_GREEN_MISSINGPAIR_RACHIO],
                    ]}
                  />
                  <SummaryWidget
                    // homes={homes}
                    calendarType="all"
                    label="all home appointments"
                  />
                  <SummaryWidget
                    // homes={homes}
                    showOtherCalendarEvents
                    calendarType="all"
                    label="all upcoming appointments"
                  />
                </TabViewContainer>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Snapshot;
