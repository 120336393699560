import { fetchUser } from "helpers/SrApiCalls";
import { useLocation } from "../../node_modules/react-router-dom/dist/index";
import React from "react";

export const loggedIn = () => {
  const expirationTime = localStorage.getItem("tokenExpirationTime");
  return new Date().getTime() / 1000 < parseInt(expirationTime, 10);
};

export async function updateUserData(dispatch) {
  fetchUser({ id: localStorage.getItem("token") }, dispatch);
}

export const login = ({ token, tokenExpirationTime, id }) => {
  localStorage.setItem("token", token);
  localStorage.setItem("tokenExpirationTime", tokenExpirationTime);
  localStorage.setItem("id", id);
};

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const unlogin = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExpirationTime");
  localStorage.removeItem("id");
  window.location.reload();
};

export const headerWithAuth = () => {
  //return 'fakeTok';
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
};

export const headerOnlyAuth = () => {
  //return 'fakeTok';
  return { Authorization: "Bearer " + localStorage.getItem("token") };
};

/* Validation state should be an object with an optional message for each object.  
This is returned from the API.

If no key exist for this object, no text should be displayed. 
*/
export const getHelperText = (validationState, key) => {
  let helperText = "";

  if (validationState)
    if (validationState[key]) {
      const correctState = validationState[key];
      // we have a string message
      if (correctState.isArray) {
        correctState.forEach((v) => {
          helperText += v.toString();
        });
      } else if (typeof helperText === "object") {
        helperText = correctState.message;

        // helper text is null
        if (!helperText)
          helperText =
            "Error message in incorrect format.  Check API endpoint return.";
      } else {
        helperText = correctState;
      }
    }

  return helperText;
};

/*Just a boolean telling us if there's a validation object for this key.*/
export const getErrorState = (validationState, key) => {
  let errorState = false;

  if (validationState) if (validationState[key]) errorState = true;

  return errorState;
};
