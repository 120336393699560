import React from 'react';
import staticImage from "../assets/images/loading.gif";
import './LoadingMask.css';
import { Typography} from '@mui/material';

const LoadingMask = (props) => {

  return (
    <div className="container">
    <img style={{ height: "100px" }} alt="Loading" src={staticImage} className="image" />
    <Typography variant="h6">{props.message}</Typography>
</div>
  );
};
export default LoadingMask;