/**
 * The base colors for the COMET-Farm React.
 * You can add new color using this file.
 * You can customized the colors for the entire COMET-Farm React using thie file.
 */

export default {
  background: {
    default: "#f0f2f5",
    header: "#eee",
  },

  text: {
    main: "#7b809a",
    focus: "#7b809a",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#fafafa",
    focus: "#fafafa",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  slate: {
    main: "#2c303c",
    focus: "#2a2c37",
    50: "#2c3c5880",
    25: "#c3cbd4",
  },

  primary: {
    main: "#65653f",
    75: "#65653fc0",
    50: "#65653f80",
    25: "#65653f40",
    5: "#65653f0a",
    focus: "#65653fc0",
  },

  secondary: {
    main: "#b18b4a",
    focus: "#b18b4a7f",
  },

  tertiary: {
    main: "#63421f",
    focus: "#ce7426",
  },
  color4: {
    main: "#9c5416",
    focus: "#ce7426",
  },

  info: {
    main: "#2f3324",
    focus: "#42c416",
    dullFocus: "#64643c",
    dullOff: "#8f8e7f",
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "#2f3324",
    focus: "#2c3c58",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#65653f",
      state: "#65653ffe",
    },

    secondary: {
      main: "#b18b4a",
      state: "#b18b4a",
    },

    info: {
      main: "#b48b4b",
      state: "#d8891b",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#42424a",
      state: "#191919",
    },
  },

  socialMediaColors: {
    white: {
      main: "#ffffff",
      focus: "#ffffff",
    },
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#ab8e7b",
      text: "#7d3705",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: "#c9c97f",
      text: "#90c609",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#945712",
    secondary: "#110e0e",
    info: "#4f605b",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};
