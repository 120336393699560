const EvaluateGeneralYesNoQuestion = (answer) => {
  return answer && answer.trim().toLowerCase() === "yes";
};
const BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS = {};

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Home Is Ready for Commissioining - All systems Installed and Ready for Home Automation Setup"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify Fiber installed in Conduit"] =
  EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Fiber Modem Installed, Active and Powered On"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify Modem has Active Internet"] =
  EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Wi-Fi Hardware Installed or Available (Base and Extenders)"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Water Meter Panel Installed (Meter(s) Functional, Display, Orientation; Type)"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify Premise has Electric Meter"] =
  EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify Premise has Gas Meter"] =
  EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Premise has THREE Smart Switches (Z-Wave)"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Premise has TWO Smart Dimmers (Z-Wave)"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Two Electrical Duplex boxes (4) Outlets Installed inside of Network Media Panel"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify One Electrical Duplex box (2) Outlets Installed below Network Media Panel"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify One Electrical Duplex box (2) Outlets Installed below Low Volt Security Panel"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify One Electrical Duplex box (2) Outlets Installed below Water Meter Panel"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify One Electrical Duplex box (2) Outlets (GFCI) Installed near Irrigation Controller"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Security Panel is installed and wired per Siemens Drawings (includes Battery, Transformer)"
] = EvaluateGeneralYesNoQuestion;

//BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify Security Installer placed documentation in Kitchen drawer"]= EvaluateGeneralYesNoQuestion
BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify CAT6 Cable between Security Panel and Network Panel is installed per Siemens Drawings"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Security Keypad Programming set-up completed according to the Siemens Commissioning Process"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify the System Passes the Walk Test Feature ([MasterCode]+50)"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify the System has Real-Time Clock set to current Date/Time ([MasterCode]+#63)"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Security Keypad is installed and functional with Zone Descriptions/Locations"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Security Siren is installed and functional"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Security PIR Motion is installed and functional"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify the status of Zone 2 reports properly at the Keypad"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify the status of Zone 3 reports properly at the Keypad"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify the status of Zone 4 reports properly at the Keypad"
] = EvaluateGeneralYesNoQuestion;

//BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify the status of Zone 5 reports properly at the Keypad"]= EvaluateGeneralYesNoQuestion
//BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify the status of any additional Zone(s) report properly at the Keypad"]= EvaluateGeneralYesNoQuestion
BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify HVAC Z-Wave Thermostat installed on Main Level"
] = EvaluateGeneralYesNoQuestion;

//BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify HVAC Z-Wave Thermostat installed on OTHER Level(s) (Total Qty in comments)"]= EvaluateGeneralYesNoQuestion

// BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify Irrigation Controller is installed according to the Siemens Drawings"]= EvaluateGeneralYesNoQuestion
BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Fiber Modem Installed, Active and Powered On (Add Photo in Details) If installed, Add photo of ONT Creds in Details"
] = EvaluateGeneralYesNoQuestion;

BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[
  "Verify Modem has Active Internet (Escalate Immediately if Marked No - Complete Issue report)"
] = EvaluateGeneralYesNoQuestion;

//BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS["Verify Irrigation Controller is installed according to the Siemens Drawings (Create Issue if NOT Installed)"]= EvaluateGeneralYesNoQuestion

const EvaluateBuilderChecklistQuestions = (answer, question) => {
  if (BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[question]) {
    return BUILDER_READY_GEN3_QUESTIONS_AND_ACTIONS[question](answer);
  }

  return true;
};

export const validateBuilderChecklists = (checklists) => {
  const issues = [];
  const bdlrChecklist = checklists.filter(
    (x) =>
      x.name.replaceAll(" ", "").toLowerCase() ===
      "cabbuilderreadinesschecklistgen3",
  )[0];

  if (bdlrChecklist) {
    for (
      let i = 0;
      i < bdlrChecklist?.bimChecklistDetail?.checklist_items.length;
      i += 1
    ) {
      const theIssue = bdlrChecklist?.bimChecklistDetail?.checklist_items[i];
      const question = theIssue.question_text;
      const answer = theIssue.custom_field_values.value;

      if (!EvaluateBuilderChecklistQuestions(answer, question)) {
        // console.log(answer, question);
        // console.log(EvaluateBuilderChecklistQuestions(answer, question));
        issues.push(question);
      }
    }
  }
  return issues;
};
