import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import {
  Link as MUILink,
  Paper,
  FormControl,
  FormControlLabel,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SRInput from "components/inputs/index";
import SRButton from "components/buttons/index";
import ServerDialog from "components/dialogs/ServerDialog";
import colors from "assets/theme/base/colors";
import {
  REGISTER_COMP,
  REGISTER_C_P,
  REGISTER_E,
  REGISTER_F_N,
  REGISTER_L_N,
  REGISTER_P,
} from "constants/formKeys";

const { info } = colors;

// Repeated style rules for reg
const sx_reg = {
  p: "10px",
  fieldset: {
    borderColor: info.dullFocus,
    borderStyle: "solid",
    borderWidth: "2px",
  },
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://axiossoft.com/">
        Axios Software Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function UpdatePasswordForm(props) {
  const { handleSubmit, submitting, showPass, helperTextFunc, errorStateFunc } =
    props;

  const [localPopupState, updateLocalPopup] = useState({});

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        sx={{
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Box
          component="form"
          method="post"
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: "2vh",
          }}
        >
          <Grid
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            container
            component="main"
          >
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                padding: ".5em",
              }}
            >
              <Grid item>
                <Typography component="h1" variant="h5" sx={{ mb: "1vh" }}>
                  Sterling Ranch Management Tool
                </Typography>
              </Grid>
              <Avatar sx={{ bgcolor: "secondary.main" }}>
                <AccountCircleIcon />
              </Avatar>
              <Grid item xs={6} sm={6} md={6}>
                <Typography sx={{ fontSize: "24px", marginBottom: "10px" }}>
                  Update Password
                </Typography>
              </Grid>

              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                item
              >
                <Grid item xs={12} sm={12} md={12}>
                  <SRInput
                    required
                    sx={{
                      padding: "10px",
                      fieldset: {
                        borderColor: errorStateFunc(REGISTER_P.key)
                          ? "red"
                          : info.dullFocus,
                        borderStyle: "solid",
                        borderWidth: "2px",
                      },
                    }}
                    key={REGISTER_P.key}
                    name={REGISTER_P.key}
                    placeholder={REGISTER_P.label}
                    type="password"
                    error={errorStateFunc(REGISTER_P.key)}
                    label={REGISTER_P.label}
                    helperText={helperTextFunc(REGISTER_P.key)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <SRInput
                    required
                    sx={{
                      padding: "10px",
                      fieldset: {
                        borderColor: errorStateFunc(REGISTER_C_P.key)
                          ? "red"
                          : info.dullFocus,
                        borderStyle: "solid",
                        borderWidth: "2px",
                      },
                    }}
                    error={errorStateFunc(REGISTER_C_P.key)}
                    helperText={helperTextFunc(REGISTER_C_P.key)}
                    key={REGISTER_C_P.key}
                    name={REGISTER_C_P.key}
                    placeholder={REGISTER_C_P.label}
                    type="password"
                    label={REGISTER_C_P.label}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <SRButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit Form
              </SRButton>

              <Grid
                item
                container
                spacing={0}
                justify="flex-end"
                alignItems="center"
              >
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="center"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid item>
                    <MUILink
                      to="/login"
                      style={{
                        fontStyle: "italic",
                        color: info.dullFocus,
                        textDecoration: "underline",
                      }}
                      component={Link}
                    >
                      Already have an account, login here
                    </MUILink>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {localPopupState && localPopupState.showPopup && (
          <ServerDialog
            {...localPopupState}
            updatePopup={(e) => updateLocalPopup(e)}
          />
        )}
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
