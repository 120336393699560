// types
import { createSlice } from '@reduxjs/toolkit';

const getDefaultSystem = (systems) =>
{
    var defaultSystem = {id: 0, isOnline: false, name: 'none'};

    if(systems)
    if(systems.length > 0)
    {
        defaultSystem = Object.assign({}, systems[0]);
    }

    return defaultSystem;
}


// initial state
const initialState = {
    bimAuthResponse: {
        response: null,
        status: "Disconnected",
        success: false,
        message: ""
    },
    copperAuthResponse: {
        response: null,
        status: "Disconnected",
        success: false,
        message: ""
    },
    freshdeskAuthResponse: {
        response: null,
        status: "Disconnected",
        success: false,
        message: ""
    },
    googleAuthResponse: {
        response: null,
        status: "Disconnected",
        success: false,
        message: ""
    }
};

const connection = createSlice({
    name: 'connection',
    initialState,
    reducers: {
        update(state, action) {
            state.bimAuthResponse = action.payload.bimAuthResponse != null ? action.payload.bimAuthResponse: initialState.bimAuthResponse;
            state.copperAuthResponse = action.payload.copperAuthResponse != null ? action.payload.copperAuthResponse: initialState.copperAuthResponse;
            state.freshdeskAuthResponse = action.payload.freshdeskAuthResponse != null ? action.payload.freshdeskAuthResponse: initialState.freshdeskAuthResponse;
            state.googleAuthResponse = action.payload.googleAuthResponse != null ? action.payload.googleAuthResponse: initialState.googleAuthResponse;
        }
    }
});

export default connection.reducer;

export const { update } = connection.actions;
