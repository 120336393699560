import { useEffect, useState } from "react";
import moment from "../../../node_modules/moment/moment";
import { dispatch } from "store/index";
import { updateLoading } from "store/reducers/mask";
import { fetchHomeData, fetchPureCalendarEvents } from "helpers/SrApiCalls";
import { Grid } from "../../../node_modules/@mui/material/index";
import CustomDateRange from "components/date/CustomDateRange";
import { defaultDate } from "helpers/Service";
import { DATE_FILTER_MASK_ID } from "constants/api";

export const FetchHomesDateFilter = (props) => {
  const { setHomes, dates, setDates, setOtherCalendarEvents } = props;
  const [dateRange, setDateRange] = useState([
    dates && dates[0] ? dates[0] : defaultDate()[0],
    dates && dates[1] ? dates[1] : defaultDate()[1],
  ]);

  const doFetches = async () => {
    dispatch(updateLoading({ loading: true, fromId: DATE_FILTER_MASK_ID }));

    await Promise.all([fetchCalendarEvents, fetchHomes]).finally(() => {
      dispatch(updateLoading({ loading: false, fromId: DATE_FILTER_MASK_ID }));
    });
  };

  const fetchCalendarEvents = async () => {
    if (setOtherCalendarEvents) {
      var apiResponse = await fetchPureCalendarEvents(dateRange);

      if (apiResponse) {
        setOtherCalendarEvents(apiResponse.calendarEvents);
      } else {
        console.log("Fetching calendar events failed");
      }
    }
  };

  const fetchHomes = async () => {
    var newHomes = await fetchHomeData(dateRange);

    if (newHomes) {
      setHomes(newHomes.allHomes);
    } else {
      console.log("Fetching Homes failed");
    }
  };

  useEffect(() => {
    if (setDates) {
      setDates(dateRange);
    }
    doFetches();
    fetchHomes();
    fetchCalendarEvents();
  }, [dateRange]);

  return (
    <Grid
      container
      rowSpacing={4.5}
      columnSpacing={2.75}
      sx={{ marginLeft: ".5em" }}
    >
      <Grid item xs={12}>
        <Grid item xs={12} sx={{ paddingTop: "1em" }}>
          <CustomDateRange updateRange={setDateRange} dateRange={dateRange} />
        </Grid>
      </Grid>
    </Grid>
  );
};
