import * as React from "react";

import { Box, Paper } from "@mui/material";

import { styled, useTheme, alpha } from "@mui/material/styles";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

import moment from "moment";

import {
  CheckCircleOutline,
  Close,
  QuestionMark,
} from "../../../../node_modules/@mui/icons-material/index";
import {
  Tooltip,
  Typography,
} from "../../../../node_modules/@mui/material/index";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.missing`]: {
    color: theme.palette.common.white,

    backgroundColor: theme.palette.grey[500],
    "&:hover, &.Mui-hovered": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[600],
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const Cell = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
}));

const CellHead = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
  fontWeight: "bold",
}));

const RowTitle = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
}));

const renderDateStatus = (params) => {
  if (!params.formattedValue) {
    return null;
  } else if (params.formattedValue === "U") {
    return (
      <Tooltip title="Unknown Status">
        <QuestionMark style={{ color: "orange" }} />
      </Tooltip>
    );
  } else {
    if (params.formattedValue === "Y") {
      return (
        <Tooltip title="Connected">
          <CheckCircleOutline style={{ color: "green" }} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Disconnected">
          <Close style={{ color: "maroon" }} />
        </Tooltip>
      );
    }
    return params.formattedValue;
  }
};

const renderStatus = (params) => {
  //console.log("copper", params);
  if (!params.row[0]) {
    return <strong>Meter Not Found</strong>;
  }
  return (
    <strong style={{ textTransform: "capitialize" }}>
      {params.formattedValue[0].toUpperCase() + params.formattedValue.substr(1)}
    </strong>
  );
};
const renderType = (params) => {
  //console.log("copper", params);
  if (!params.row[0]) {
    return params.formattedValue;
  }
  return params.formattedValue;
};

const columns = [
  {
    field: "type",
    headerName: "Meter",
    width: 125,
    renderCell: (params) => renderType(params),
  },
  {
    field: "currentState",
    headerName: "Current Status",
    width: 125,
    renderCell: (params) => renderStatus(params),
  },
];
for (let i = 0; i < 7; i += 1) {
  columns.push({
    field: "" + i,
    width: 50,
    headerName: moment().add(-i, "d").format("MM-DD"),
    renderCell: (params) => renderDateStatus(params),
  });
}

const formatCopperRecord = (currentState, valuesByTime) => {
  const theReturn = {};
  if (!currentState) {
    return theReturn;
  }

  let currentStatus =
    currentState === "connected" || currentState === "active" ? "Y" : "N";
  if (currentState === "learning" || currentState == "scanning") {
    currentStatus = "U";
  }
  var oldStatus = "U";
  const dayStartStatus = {};

  for (var i = 0; i < 7; i += 1) {
    const startDayQuestion = moment()
      .startOf("day")
      .add(-6 + i, "d");
    const endDayQuestion = moment()
      .endOf("day")
      .add(-6 + i, "d");

    dayStartStatus[6 - i] = oldStatus;

    if (valuesByTime && valuesByTime.length > 0) {
      const eventsInDay = valuesByTime.filter((x) =>
        moment(x.date).isBetween(
          startDayQuestion,
          endDayQuestion,
          undefined,
          "[)",
        ),
      );
      if (eventsInDay && eventsInDay.length > 0) {
        const dayEvents = eventsInDay.sort(
          (a, b) => moment(b.date) - moment(a.date),
        );

        if (
          dayEvents.at(-1).value === "connected" ||
          dayEvents.at(-1).value === "active" ||
          dayEvents.at(-1).value === "learning" ||
          dayEvents.at(-1).value === "scanning"
        ) {
          oldStatus = "Y";
          //The first thing that happened in the day was the status changing to connected,
          //so that means it was not connected to begin with
        } else {
          //the first thing that happened was the status was changed to disconnected or degraded
          //so we can't actually make any assumptions about
          oldStatus = "N"; //day ended badly
        }
      }
    }
  }

  for (let i = 0; i < 7; i += 1) {
    const startDayQuestion = moment().startOf("day").add(-i, "d");
    const endDayQuestion = moment().endOf("day").add(-i, "d");

    if (valuesByTime && valuesByTime.length > 0) {
      const eventsInDay = valuesByTime.filter((x) =>
        moment(x.date).isBetween(
          startDayQuestion,
          endDayQuestion,
          undefined,
          "[)",
        ),
      );
      const dayEvents = eventsInDay
        .sort((a, b) => moment(b.date) - moment(a.date))
        .reverse();
      if (dayEvents && dayEvents.length > 0) {
        if (currentStatus === "Y") {
          //we went into the day good
          theReturn[i] = currentStatus;
        } else if (
          dayEvents.some(
            (x) =>
              x.value === "connected" ||
              x.value === "active" ||
              x.value === "learning" ||
              x.value === "scanning",
          )
        ) {
          //the meter was good at any point in the day

          theReturn[i] = "Y";
        } else {
          theReturn[i] = "N"; //otherwise we add N
        }

        currentStatus = dayStartStatus[i];
      } else {
        theReturn[i] = currentStatus;
      }
    } else {
      theReturn[i] = currentStatus;
    }
  }

  return theReturn;
};

export default function CopperRecordCard(props) {
  //console.log("columns", columns);
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(4);

  const { copperRecords } = props;

  //each existing meter should have a premise
  const anyMeter =
    copperRecords.gas ??
    copperRecords.indoorWater ??
    copperRecords.outdoorWater ??
    copperRecords.powerNet ??
    null;

  //OK so need to process the rows
  const rows = [];

  rows.push({
    id: copperRecords?.gas?.id ?? "Gas",
    type: "Gas",
    currentState: copperRecords?.gas?.state,
    ...formatCopperRecord(
      copperRecords?.gas?.state,
      copperRecords?.gas?.valuesByTime,
    ),
  });
  rows.push({
    id: copperRecords?.indoorWater?.id ?? "Indoor Water",
    type: "Indoor Water",
    currentState: copperRecords?.indoorWater?.state,
    ...formatCopperRecord(
      copperRecords?.indoorWater?.state,
      copperRecords?.indoorWater?.valuesByTime,
    ),
  });

  rows.push({
    id: copperRecords?.outdoorWater?.id ?? "Outdoor Water",
    type: "Outdoor Water",
    currentState: copperRecords?.outdoorWater?.state,
    ...formatCopperRecord(
      copperRecords?.outdoorWater?.state,
      copperRecords?.outdoorWater?.valuesByTime,
    ),
  });

  rows.push({
    id: copperRecords?.powerNet?.id ?? "Power Net",
    type: "Power Net",
    currentState: copperRecords?.powerNet?.state,
    ...formatCopperRecord(
      copperRecords?.powerNet?.state,
      copperRecords?.powerNet?.valuesByTime,
    ),
  });

  return (
    <Box
      // homes={homes}
      sx={{ width: "100%", height: 250, padding: ".5em" }}
      label="Copper Status"
    >
      <Typography variant="h4">{`Premise ${
        anyMeter
          ? `Registered On ${moment(anyMeter.copperPremise.created_at).format(
              "MM/DD/YYYY",
            )}`
          : "Not Registered"
      }`}</Typography>

      <StripedDataGrid
        getRowHeight={() => "auto"}
        columns={columns}
        rows={rows}
        rowThreshold={0}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[4, 10, 20, 50]}
        pagination
        initialState={{
          sorting: {
            sortModel: [{ field: "date", sort: "asc" }],
          },
        }}
        getRowClassName={(params) => {
          if (!params.row[0]) {
            return "missing";
          } else {
            return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
          }
        }}
        sx={{
          [`& .${gridClasses.row}`]: {
            "&:hover, &.Mui-hovered": {
              backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
          },
        }}
      />
    </Box>
  );
}
