import { useRoutes } from "react-router-dom";

// project import
import { MainRoutes, LoginOnlyRoutes } from "./AllRoutes";

// ==============================|| ROUTING RENDER ||============================== //

/*Filters routes based on if the user is logged in and the users role.
If the user isn't logged in, the only routes available will be the LoginOnlyRoutes.  
Children will be limited to login related routes.

Once the user is logged in, all routes are filtered by role and all available login routes can be navigated to.
*/
export default function ThemeRoutes(props) {
 //  console.log("all props rotes", props);
  let role = props.role;

  if( role == null || (typeof role === "string" && role.trim().length === 0) )
    role = "b";

  // console.log(MainRoutes);
  let mainFilteredChildren = MainRoutes.children.filter(
    (r) =>
      r.access.includes(role) ||
    role === "a" ||
      r.access.length === 0,
  );
  // console.log("filtered main", mainFilteredChildren);
  let copyOfMainRoutes = Object.assign({}, MainRoutes);
  let copyOfLoginRoutes = Object.assign({}, LoginOnlyRoutes);

  copyOfMainRoutes.children = Object.assign([], mainFilteredChildren);
  copyOfLoginRoutes.children = props.authenticated
    ? Object.assign([], LoginOnlyRoutes.children2)
    : Object.assign([], LoginOnlyRoutes.children);
  // console.log("filtered children: ", copyOfMainRoutes.children);
  // console.log("user role: ", role);

  let listOfAllRoutes = props.authenticated
    ? [copyOfMainRoutes]
    : [copyOfLoginRoutes];

  // console.log("here are the routes we have", copyOfLoginRoutes);
  return useRoutes(listOfAllRoutes);
}
