// assets
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    WindowsOutlined
} from '@ant-design/icons';
import { getPageAccess } from 'helpers/General';

// icons
const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    WindowsOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
    id: 'dev-items',
    title: 'Dev Mode - Axios Widgets',
    type: 'group',
    children: [
        {
            id: 'dev-typography',
            title: 'Style Items',
            access: getPageAccess(),
            type: 'item',
            url: '/style',
            icon: icons.FontSizeOutlined
        },
        {
            id: 'dev-color',
            title: 'Colors',
            access: getPageAccess(),
            type: 'item',
            url: '/color',
            icon: icons.BgColorsOutlined
        },
        {
            id: 'dev-shadow',
            title: 'Shadow',
            access: getPageAccess(),
            type: 'item',
            url: '/shadow',
            icon: icons.BarcodeOutlined
        },
        {
            id: 'dev-widgets',
            title: 'Widgets',
            access: getPageAccess(),
            type: 'item',
            url: '/widgets',
            icon: icons.WindowsOutlined
        }
    ]
};

export default utilities;
