export const POWER_TYPE = "power";
export const POWER_NET = "power_net";
export const POWER_GEN = "power_gen";
export const POWER_SUB = "power_sub";
export const WATER = "water";
export const WATER_OUTDOOR = "outdoor_water";
export const WATER_INDOOR = "indoor_water";
export const GAS = "gas";
export const CALENDAR_COLORS = ["Graphite", "Banana", "Tomato", "Tangerine", "Sage", "Basil", "Peacock", "Blueberry", "Lavender", "Flamingo", "Grape"];
export const BIM_STATUS = ["OpenCLA", "OpenCAB"];
export const FRESH_STATUS = ["FreshOpen", "FreshClosed", "FreshScheduled"];
export const COPPER_ENERGY_TYPES = ["power_net", "outdoor_water", "indoor_water", "gas"];