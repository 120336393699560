// assets
import {
  DashboardOutlined,
  DatabaseOutlined,
  ScheduleOutlined,
  SettingOutlined,
  HomeOutlined,
  BuildOutlined,
  CameraOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import {
  ADMIN,
  BUILDERS,
  DASHBOARD,
  DEVPAGES,
  getPageAccess,
  HOMES,
  QUERY,
  SCHEDULE,
  WIDGET,
} from "helpers/General";

// icons
const icons = {
  CameraOutlined,
  DashboardOutlined,
  ScheduleOutlined,
  DatabaseOutlined,
  SettingOutlined,
  HomeOutlined,
  BuildOutlined,
  ControlOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
/*{
      id: "schedule",
      title: "Schedule",
      type: "item",
      url: "/schedule",
      access: getPageAccess(SCHEDULE),
      icon: icons.ScheduleOutlined,
      breadcrumbs: false,
    },
    {
      id: "query",
      title: "Query",
      type: "item",
      url: "/query",
      access: getPageAccess(QUERY),
      icon: icons.DatabaseOutlined,
      breadcrumbs: false,
    },
    {
      id: "homes",
      title: "Home Management",
      type: "item",
      url: "/homes",
      access: getPageAccess(HOMES),
      icon: icons.HomeOutlined,
      breadcrumbs: false,
    },
    {
      id: "builders",
      title: "Builder Contacts",
      type: "item",
      url: "/builders",
      access: getPageAccess(BUILDERS),
      icon: icons.BuildOutlined,
      breadcrumbs: false,
    }, */
const dashboard = {
  id: "group-dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      access: getPageAccess(DASHBOARD),
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },

    {
      id: "account",
      title: "Account",
      type: "item",
      url: "/account",
      access: getPageAccess(DEVPAGES),
      icon: icons.SettingOutlined,
      breadcrumbs: false,
    },

    {
      id: "admin",
      title: "Admin",
      type: "item",
      url: "/admin",
      access: getPageAccess(ADMIN),
      icon: icons.ControlOutlined,
      breadcrumbs: false,
    },
    /*{
      id: "widget",
      title: "Widgets",
      type: "item",
      url: "/widget",
      access: getPageAccess(WIDGET),
      icon: icons.CameraOutlined,
      breadcrumbs: false,
    },*/
  ],
};

export default dashboard;
