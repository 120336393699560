export const header = () => 
{
  return { 
    'Accept': "application/json",
    'Content-Type': "application/json"
};

};

export const headerOnlyAuth = () => 
{
  return {'Authorization': 'Bearer ' + localStorage.getItem("token")};

};