// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import connection from './connection';
import mask from './mask';
import user from './user';
import homes from './homes';
import validation from './validation';
import popup from './popup';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, connection, mask, user, homes, validation, popup });

export default reducers;
