import { Grid, Paper } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { updateLoading } from "store/reducers/mask";

import { getHelperText, getErrorState, useQuery } from "utils/auth";
import { capitalizeFirstLetter } from "helpers/General";
import { updatePassword } from "helpers/SrApiCalls";

import UpdatePasswordForm from "components/forms/UpdatePasswordForm";

import { REGISTER_C_P, REGISTER_P } from "constants/formKeys";
import { updatePopup } from "store/reducers/popup";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
const backgroundToUse = require("assets/images/home-page-2.png");

const UpdatePassword = () => {
  const validationState = useSelector((state) => state.validation.validations);
  const dispatch = useDispatch();
  const query = useQuery();
  // console.log('validation state : ', validationState);
  const nav = useNavigate();

  const submit = async (e) => {
    e.preventDefault();

    const data = JSON.parse(atob(query.get("confirm")));
    data.password = e.target[REGISTER_P.key].value;
    data.confirm = e.target[REGISTER_C_P.key].value;

    /* I suppose it will need to be that we send in dispatch.  
      Since we don't have sagas and there's more than one unique object shape to expect from any return. */
    var returnObj = await updatePassword(data, dispatch);

    // console.log('return obj from login : ', returnObj);
    dispatch(updateLoading({ loading: false }));
  };

  // Pasing this down to avoid accessing reducer in component : TODO update this
  const helperTextFunc = (fieldKey) =>
    getHelperText(validationState, fieldKey) ||
    getHelperText(validationState, capitalizeFirstLetter(fieldKey));

  //Pasing this down to avoid accessing reducer in component : TODO update this
  const errorStateFunc = (fieldKey) =>
    getErrorState(validationState, fieldKey) ||
    getErrorState(validationState, capitalizeFirstLetter(fieldKey));

  return (
    <Grid container component="main">
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${backgroundToUse})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <UpdatePasswordForm
          handleSubmit={submit}
          helperTextFunc={helperTextFunc}
          errorStateFunc={errorStateFunc}
        />
      </Grid>
    </Grid>
  );
};

export default UpdatePassword;
