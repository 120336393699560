import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import moment from "moment";
import { alpha, styled, useTheme } from "@mui/material/styles";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));
const clickableLink = (params) => {
  if (!params.formattedValue) {
    return null;
  }
  return (
    <a href="params.formattedValue" target="_blank">
      {params.formattedValue}
    </a>
  );
};

export default function FreshdeskTicketsTable(props) {
  const theme = useTheme();
  const [pageSize, setPageSize] = useState(3);

  const columns = [
    { field: "date", headerName: "Date Created", width: 100 },
    { field: "address", headerName: "Address", width: 175 },
    { field: "ticket_id", headerName: "Ticket ID" },
    { field: "type", headerName: "Type" },

    {
      field: "link",
      headerName: "Link",
      renderCell: (params) => clickableLink(params),
      width: 300,
    },
  ];

  const rows = props?.tickets.map((inticket, key) => {
    let ticket = { ...inticket };

    if (ticket)
      return {
        date: moment(ticket.created_at, "YYYY-MM-DDTHH:mm:ss").format(
          "MM-DD-YYYY",
        ),
        address: ticket.custom_fields?.cf_address,
        ticket_id: ticket.id,
        type: ticket.type,
        link: ticket.link,

        id: key,
      };
  });
  console.log(rows);

  return (
    <Box sx={{ width: "100%", height: 265 }}>
      <StripedDataGrid
        columns={columns}
        rows={rows}
        rowThreshold={0}
        onRowClick={(params) => {
          props.showHomeDetails(rows[params.id].home);
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[3, 10, 20, 50]}
        pagination
        initialState={{
          sorting: {
            sortModel: [{ field: "date", sort: "asc" }],
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        sx={{
          [`& .${gridClasses.row}`]: {
            "&:hover, &.Mui-hovered": {
              backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
          },
        }}
      />
    </Box>
  );
}

FreshdeskTicketsTable.propTypes = {
  tickets: PropTypes.array,
};
