import * as React from "react";

import { Grid, Typography, Card, CardContent, Box, Paper } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import {
  getShortSummaryFromSrDescription,
  getShortSummaryFromAxiosDescription,
} from "helpers/Service";
import { styled, useTheme, alpha } from "@mui/material/styles";
import moment from "moment";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";
import TabViewContainer from "components/containers/tabViewContainer";
import BimChecklistTable from "../dashboard/BimChecklistTable";
import { Button } from "../../../../node_modules/@mui/material/index";
import DynamicDialog from "components/dialogs/Dialog";
import { getDialogButton } from "helpers/General";
import { useState } from "react";

const Cell = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
}));

const CellHead = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
  fontWeight: "bold",
}));

const RowTitle = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
}));

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const renderDate = (params) => {
  return <div>{params.value.format("MM-DD-YYYY")}</div>;
};

//OK so this is the full history
//so evvery thing all lumped together and arranged be relevent date
export default function HomeHistoryCard(props) {
  const theme = useTheme();

  const [showHtmlDialog, setShowHtmlDialog] = useState(false);
  const [htmlDialog, setHtmlDialog] = useState();

  const resetDialogs = () => {
    setShowHtmlDialog(false);
  };

  var closeButton = getDialogButton("Close", resetDialogs);

  const renderDescription = (params) => {
    const { id } = params;

    if (
      params.row.eventType === "Truck Roll" ||
      params.row.eventType === "SR Calendar Closings"
    ) {
      return (
        <div key={params.row.id}>
          <Button
            onClick={() => {
              setHtmlDialog(params.row.event.calendarNotes);
              setShowHtmlDialog(true);
            }}
          >
            Show Full Description
          </Button>

          {params.formattedValue}
        </div>
      );
    } else {
      return <div key={params.row.id}>{params.formattedValue}</div>;
    }
  };

  const Columns = [
    {
      field: "date",
      headerName: "Date",
      width: 90,
      renderCell: (params) => renderDate(params),
    },
    { field: "eventType", headerName: "Event Type", width: 150 },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      renderCell: (params) => renderDescription(params),
    },
    { field: "status", headerName: "Status", width: 250 },
  ];

  const { home } = props;

  let eventList = [];

  if (home.axiosCalendarEvents) {
    for (let i = 0; i < home.axiosCalendarEvents.length; i += 1) {
      let event = home.axiosCalendarEvents[i];
      eventList.push({
        id: "axioscal" + event.homeEventId,
        date: new moment(event.closingTime, "YYYY-MM-DDTHH:mm:ss"),
        description: getShortSummaryFromAxiosDescription(event.description),
        status: event.status,
        event,
        eventType: "Truck Roll",
      });
    }
  }
  if (home.srCalendarEvents) {
    for (let i = 0; i < home.srCalendarEvents.length; i += 1) {
      let event = home.srCalendarEvents[i];
      eventList.push({
        id: "srmanCal" + event.homeEventId,
        date: new moment(event.closingTime, "YYYY-MM-DDTHH:mm:ss"),
        description: getShortSummaryFromSrDescription(event.calendarNotes),
        event,
        status: event.status,
        eventType: "SR Calendar Closings",
      });
    }
  }
  if (home.bimRecord?.bimChecklists) {
    for (let i = 0; i < home.bimRecord.bimChecklists.length; i += 1) {
      let event = home.bimRecord.bimChecklists[i];
      eventList.push({
        id: "checklist" + event.completed_checklist_id,
        date: new moment(event.updated_at, "YYYY-MM-DDTHH:mm:ss"),
        description: event.name,
        status: event.status,
        event,
        eventType: "BIM 360",
      });
    }
  }
  if (home.bimRecord?.bimIssues) {
    for (let i = 0; i < home.bimRecord.bimIssues.length; i += 1) {
      let event = home.bimRecord.bimIssues[i];
      eventList.push({
        id: "issues" + event.issue_id,
        date: new moment(event.updated_at, "YYYY-MM-DDTHH:mm:ss"),
        description: event.description,
        status: event.status,
        event,
        eventType: "BIM 360 Issue Status",
      });
    }
  }
  if (home.copperMeters) {
    for (let i = 0; i < home.copperMeters.length; i += 1) {
      let event = home.copperMeters[i];
      eventList.push({
        id: "copper" + event.id,
        date: new moment(event.created_at, "YYYY-MM-DDTHH:mm:ss"),
        description: "Copper meter added " + event.type,
        status: event.state,
        event,
        eventType: "CopperLabs Event",
      });
    }

    if (home.tickets) {
      for (let i = 0; i < home.tickets.length; i += 1) {
        let event = home.tickets[i];
        eventList.push({
          id: "ticket" + event.id,
          date: new moment(event.created_at, "YYYY-MM-DDTHH:mm:ss"),
          description: event.type,
          status: event.subject,
          event,
          eventType: "FreshDesk Ticket",
        });
      }
    }

    eventList = eventList.filter((a) => !isNaN(a.date.valueOf()));

    return (
      <Box
        sx={{
          width: "100%",

          height: props.height
            ? props.height
            : Math.min(100 + eventList.length * 75, 800),
        }}
      >
        <Typography variant="h5" component="div">
          {"Home History"}
        </Typography>
        <StripedDataGrid
          getRowHeight={() => "auto"}
          columns={Columns}
          getRowId={(row) => row.id}
          rows={eventList}
          initialState={{
            sorting: {
              sortModel: [{ field: "date", sort: "asc" }],
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          sx={{
            [`& .${gridClasses.row}`]: {
              "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
                "@media (hover: none)": {
                  backgroundColor: "transparent",
                },
              },
            },
          }}
        />
        <DynamicDialog
          buttons={[closeButton]}
          open={showHtmlDialog}
          title={"Notes"}
          children={
            <div
              style={{
                border: "1px solid grey",
                maxHeight: "600px",
                overflowY: "auto",
                padding: "1em",
                color: "black",
              }}
              dangerouslySetInnerHTML={{
                __html: htmlDialog,
              }}
            />
          }
        />
      </Box>
    );
  }
}
