import { ADMIN_MASK_ID } from "constants/api";
import { Box, Button, Grid } from "../../../node_modules/@mui/material/index";
import { headerWithAuth } from "utils/auth";
import { useDispatch } from "react-redux";
import { updateLoading } from "store/reducers/mask";

export const APIButton = (props) => {
  const { apicall, label, setDialog } = props;
  const dispatch = useDispatch();
  const download = () => {
    dispatch(updateLoading({ loading: true, fromId: ADMIN_MASK_ID }));
    fetch(apicall, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: headerWithAuth(),
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
    })
      .then((response) => {
        if (response.status === 200) {
          return response
            .text() // return the result of the inner promise, which is an error
            .then((json) => {
              setDialog(`${label} Updated ${json}`);
              return null;
            });
        } //Some error before uploading the files
        else {
          setDialog("Upload Failure");
          return response
            .json() // return the result of the inner promise, which is an error
            .then((json) => {
              const { error } = json;
              setDialog("Upload Failure: " + error);
            });
        }
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  return (
    <Button variant="contained" onClick={download}>
      {label}
    </Button>
  );
};
