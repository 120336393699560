import { CalendarLedgend } from "helpers/Service";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "../../../node_modules/@mui/material/index";
import {
  ExpandMore,
  ExpandMoreSharp,
} from "../../../node_modules/@mui/icons-material/index";

export const CalendarKey = (props) => {
  const { openAccordionByDefault } = props;
  return (
    <Box width={400}>
    <Accordion
     defaultExpanded = {openAccordionByDefault ? openAccordionByDefault : false}
      sx={{ position: "absolute", zIndex: 2 }}
    >
      <AccordionSummary expandIcon={<ExpandMoreSharp />}>
        <strong>Google Calendar Color Key</strong>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            border: "1px solid black",
            width: "280px",
            height: `${CalendarLedgend.length * 1.58}em`,
          }}
        >
          {CalendarLedgend.map((leg) => (
            <Box
            sx={{ display: "flex", paddingLeft: ".25em" }}
              key={`leg-${leg.color}`}
            >
              <Box
                sx={{
                  width: "1em",
                  height: "1em",
                  marginTop: ".25em",
                  marginRight: ".25em",
                  backgroundColor: leg.color,
                }}
              />{" "}
              {leg.key}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
    </Box>
  );
};
