import ReactApexChart from "react-apexcharts";

const PieChart = (props) => {
  const { series, labels, colors, width, height } = props;

  const cwidth = width ? width : 500;
  const cheight = height ? height : 250;
  const options = {
    chart: {
      width: cwidth,
      type: "pie",
    },

    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  options.colors = colors;
  options.labels = labels;

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      width={cwidth}
      height={cheight}
    />
  );
};

export default PieChart;
