import { ADMIN_MASK_ID } from "constants/api";
import { Box, Button, Grid } from "../../../node_modules/@mui/material/index";
import { headerWithAuth } from "utils/auth";
import { useDispatch } from "react-redux";
import { updateLoading } from "store/reducers/mask";
import { useState } from "react";
import { headerOnlyAuth } from "utils/request";

export const UploadFileButton = (props) => {
  const { apicall, label, setDialog, type } = props;
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const upload = () => {
    if (files && files.length > 0) {
      // Create an object of formData

      const formData = new FormData();
      formData.append("files", files[0]);

      dispatch(updateLoading({ loading: true, fromId: ADMIN_MASK_ID }));
      fetch(apicall, {
        method: "POST",
        body: formData,
        headers: headerOnlyAuth(),
      })
        .then((response) => {
          dispatch(updateLoading({ loading: false, fromId: ADMIN_MASK_ID }));
          if (response.status === 200) {
            //Ok may have json data may not.

            return response
              .text() // return the result of the inner promise, which is an error
              .then((json) => {
                setDialog(`${label} Updated ${json}`);
                return null;
              });
          } //Some error before uploading the files
          else {
            setDialog("Upload Failure");
            return response
              .json() // return the result of the inner promise, which is an error
              .then((json) => {
                const { error } = json;
                setDialog("Upload Failure: " + error);
              });
          }
        })
        .catch((error) => {
          setDialog("Upload Failure: " + error);

          console.error("Error:", error);
        });
    }
  };

  const filesChange = (event) => {
    //console.log(event.target.files);

    if (event.target.files != null) {
      let numOfFiles = event.target.files.length;
      //console.log(numOfFiles);
      // console.log(event.target.files["0"]);
      let arrayOfFiles = [];

      if (numOfFiles > 0)
        for (var i = 0; i < numOfFiles; i++)
          arrayOfFiles.push(event.target.files[i]);

      setFiles(arrayOfFiles);
      //      console.log(arrayOfFiles);
    }
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={6}>
        <Button
          variant="contained"
          onClick={upload}
          disabled={files.length === 0}
        >
          {label}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <input
          type="file"
          accept={type ? type : ".xlsx"}
          onChange={filesChange}
        />
      </Grid>
    </Grid>
  );
};
