import { groupBy } from "helpers/Service";
import {
  Box,
  Grid,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import moment from "../../../../node_modules/moment/moment";
import DynamicDialog from "components/dialogs/Dialog";
import { getDialogButton } from "helpers/General";
import { useState } from "react";
import SummaryWidget from "components/widgets/summaryWidget";
import SummaryBox from "../SummaryBox";
import { validateBuilderChecklists } from "helpers/ValidateBuilderChecklistDetails";
import { DISCREPANCY } from "constants/api";

const builderIssueRegex = "Builder Issue+.*?:(.*?(?=<))";
export default function BuilderDiscrepancySummarySquare(props) {
  const { homes, dates } = props;

  const [tableDialog, setTableDialog] = useState(false);
  const [homeDetails, sethomeDetails] = useState([]);
  const [builderClicked, setbuilderClicked] = useState("");
  //homes that are closed are those whose closing date is before now
  //homes that are sucessfull are homes which are in status CX complete
  //otherwise closed with errors
  const resetDialogs = () => {
    setTableDialog(false);
  };

  var closeButton = getDialogButton("Close", resetDialogs);
  let localhomes = homes.map((h) => {
    return { ...h };
  });

  //follow the logic of the discrepancy report
  //get homes that the notes say are good,
  //check the homes against the validateBuilderChecklist
  //show the ones that have issues even though notes say are good

  //OK so homes is already homes that have closed at the date

  //homes from this set whose notes say have no issue are those which the final substring "Builder Issue" is followed by none (or some variant of)
  //see ReportHelper.cs ClosedNoIssue function.

  localhomes = localhomes.filter(
    (x) => {
      //Builder Issue(s) Remaining: None
      if (x.srCalendarEvents && x.srCalendarEvents[0]) {
        const found = [
          ...x.srCalendarEvents[0].calendarNotes.matchAll(builderIssueRegex),
        ];

        if (found && found.length > 0) {
          const lowers = found[found.length - 1][1].trim().toLowerCase();

          return lowers === "none" || lowers === "na" || lowers === "no";
        }

        console.log("regex match", x.srCalendarEvents[0].calendarNotes, found);
      }
      return false;
    },
    // x.srCalendarEvents[0].calendarColorId === 3 &&
  );

  const homesNoIssues = localhomes.filter(
    (x) => {
      const issues = validateBuilderChecklists(x.bimRecord?.bimChecklists);

      if (issues && issues.length > 0) {
        return false;
      }
      return true;
    },
    // x.srCalendarEvents[0].calendarColorId === 3 &&
  );

  localhomes = localhomes.map((h) => {
    h.alertMessage = "";
    let problems = [];
    if (!homesNoIssues.includes(h)) {
      problems.push("Builder Issue with home");
    }
    if (h.srCalendarEvents[0].calendarColorId !== 3) {
      problems.push("Event is not grape colored");
    }
    h.alertMessage = problems.join(" | ");
    return h;
  });

  const BuilderIsses = [];
  const allhomesByBuilders = groupBy(localhomes, (x) => x.builder?.companyName);

  const goodHomes = localhomes.filter((x) => x.alertMessage === "");
  const goodHomesByBuilder = groupBy(goodHomes, (x) => x.builder?.companyName);

  const emailReport = (email) => {
    const callAddress = `${DISCREPANCY}?startYearMonthDay=${moment(
      dates[0],
    ).format("YYYY,MM,DD")}&endYearMonthDay=${moment().format(
      "YYYY,MM,DD",
    )}&calId=sterlingranchlab.srco@gmail.com&shouldEmailAxiosTechs=false&failedCategory=all&targetBuilder=all&overrideEmail=${email}`;
    fetch(callAddress, {
      method: "GET",
    });
  };

  return (
    <SummaryBox
      color={"#f9e0fa"}
      reportFunction={emailReport}
      reportName="builder discrepancy"
    >
      <Grid container sx={{ padding: "1em" }}>
        <Grid item xs={12}>
          <Typography variant="h4">{`Builder Discrepancy`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle4"
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              sethomeDetails(localhomes);
              setTableDialog(true);
              setbuilderClicked(
                "Homes that should be good according to closing notes",
              );
            }}
          >{`Homes With Builder Discrepancies - ${
            localhomes.length - goodHomes.length
          }/${localhomes.length}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{`Discrepancy Snapshot`}</Typography>
        </Grid>
        {Array.from(allhomesByBuilders).map((row) => {
          return (
            <Grid
              item
              container
              xs={12}
              key={row[0]}
              onClick={() => {
                sethomeDetails(row[1]);
                setTableDialog(true);
                setbuilderClicked(row[0]);
              }}
              sx={{ cursor: "pointer", textDecoration: "underline" }}
            >
              <Grid item xs={6}>
                {`${row[0]}: `}
              </Grid>
              <Grid item xs={3}>
                {`${
                  goodHomesByBuilder.get(row[0])
                    ? row[1].length - goodHomesByBuilder.get(row[0]).length
                    : row[1].length
                } / ${row[1].length}`}
              </Grid>

              <Grid item xs={3}>
                {`${(
                  ((goodHomesByBuilder.get(row[0])
                    ? row[1].length - goodHomesByBuilder.get(row[0]).length
                    : row[1].length) /
                    row[1].length) *
                  100
                ).toFixed(0)}%`}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <DynamicDialog
        maxWidth="lg"
        fullWidth
        buttons={[closeButton]}
        open={tableDialog}
        title={builderClicked}
        //children={<HomeDetailsCard {...homeDetails} />}
        children={<SummaryWidget homes={homeDetails} />}
      />
    </SummaryBox>
  );
}
