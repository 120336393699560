import PropTypes from "prop-types";

import EventSummaryTable from "./EventSummaryTable";
import { useState } from "react";

export default function HomeSummaryTable(props) {
  let { calendarType } = props;
  const { homes, dateRange, otherCalendarEvents } = props;
  let newdate = dateRange;

  /*calendarType closing, all, srman, axios 

closing: srCalendarEvents[0]
all: all events from srCalendarEvents and axios
srman: all events from srCalendarEvents
axios: all events rom axios

*/

  calendarType = calendarType || "closing";

  let allEventsForRows = [];

  for (let i = 0; i < homes.length; i += 1) {
    let calEvents = [];
    switch (calendarType) {
      case "all":
        calEvents = [...homes[i].srCalendarEvents];
        calEvents.push.apply(calEvents, homes[i].axiosCalendarEvents);
        break;
      case "srman":
        calEvents = [...homes[i].srCalendarEvents];
        break;

      case "axios":
        calEvents = [...homes[i].axiosCalendarEvents];
        break;
      default: //closing
        if (homes[i].srCalendarEvents && homes[i].srCalendarEvents[0]) {
          calEvents.push(homes[i].srCalendarEvents[0]);
        }

        break;
    }

    allEventsForRows = allEventsForRows.concat(
      calEvents.map((x) => ({
        ...x,
        home: homes[i],
      })),
    );
  }

  allEventsForRows.push.apply(allEventsForRows, otherCalendarEvents);
  if (calendarType === "axios") {
    console.log("axios", allEventsForRows, otherCalendarEvents);
  }
  return (
    <EventSummaryTable
      {...props}
      events={allEventsForRows}
      dateRange={newdate}
    />
  );
}

HomeSummaryTable.propTypes = {
  homes: PropTypes.array,
  showHomeDetails: PropTypes.func,
};
