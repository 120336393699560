import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  loading: false,
  message: "Loading, please wait.....",
  fromId: null,
};

const mask = createSlice({
  name: "mask",
  initialState,
  reducers: {
    updateLoading(state, action) {
      //   console.log("update loading state: ", action);
      /*07/05/2024
            Attempt to solve loading mask issues.  Prior to this change any loading mask call can create a loading mask and remove a loading mask.  
            This causes loading mask to disappear prematuraly sometimes. 
            introducing a fromId.  If the from id exist and doesn't match the previous id, keep the previous loading state.
            If fromId is null, we allow the state to be updated.*/
      state.loading =
        action.payload.loading != null
          ? action.payload.fromId != null
            ? state.fromId === action.payload.fromId
              ? action.payload.loading
              : state.loading
            : action.payload.loading
          : initialState.loading;
      state.message =
        action.payload.message != null
          ? action.payload.message
          : initialState.message;

      // Only update the fromId if we are trying to show a loading mask.  We will always take the latest loading mask ID.
      if (action.payload.fromId != null)
        if (action.payload.loading) state.fromId = action.payload.fromId;
    },
  },
});

export default mask.reducer;

export const { updateLoading } = mask.actions;
