import React, { useEffect, useState } from "react";
import { Grid, Typography, Card, CardContent, TextField } from "@mui/material";

import { getDialogButton, groupByKey } from "helpers/General";
import DynamicDialog from "components/dialogs/Dialog";

import HomeSummaryCard from "components/cards/home/HomeSummaryCard";

import {
  getBimChecklistStatus,
  getCopperEnergyStatus,
  getFreshdeskTicketStatus,
  getHomeColor,
} from "helpers/Service";

import {
  BIM_STATUS,
  CALENDAR_COLORS,
  COPPER_ENERGY_TYPES,
  FRESH_STATUS,
} from "constants/list";
import HomeDetailsCard from "components/cards/home/HomeDetailsCard";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "../../../node_modules/@mui/material/index";
import ClosingsColumnChart from "components/charts/ClosingsColumnChart";
import HomeSummaryTable from "components/cards/dashboard/HomeSummaryTable";
import { FetchHomesDateFilter } from "./fetchHomesDateFilter";
import { CalendarKey } from "./calendarKey";

const filterPossibilities = [
  {
    name: "Completed",
    filter: CALENDAR_COLORS[10],
    group: "Calendar Categories",
  },
  {
    name: "No Visit",
    filter: CALENDAR_COLORS[0],
    group: "Calendar Categories",
  },
  {
    name: "In Review",
    filter: CALENDAR_COLORS[6],
    group: "Calendar Categories",
  },
  {
    name: "Box Configured",
    filter: CALENDAR_COLORS[1],
    group: "Calendar Categories",
  },
  {
    name: "Hardware Prepped - Builder Issues",
    filter: CALENDAR_COLORS[2],
    group: "Calendar Categories",
  },
  {
    name: "Rachio Missing",
    filter: CALENDAR_COLORS[3],
    group: "Calendar Categories",
  },
  {
    name: "CX Partial Complete",
    filter: CALENDAR_COLORS[7],
    group: "Calendar Categories",
  },
  {
    name: "Network, ONT Missing, WG",
    filter: CALENDAR_COLORS[8],
    group: "Calendar Categories",
  },
  {
    name: "Copper Data Missing",
    filter: CALENDAR_COLORS[9],
    group: "Calendar Categories",
  },
  { name: "Utilities Open", filter: BIM_STATUS[0], group: "BIM Status" },
  { name: "CAB Open", filter: BIM_STATUS[1], group: "BIM Status" },
  { name: "Open", filter: FRESH_STATUS[0], group: "FreshDesk Status" },
  { name: "Closed", filter: FRESH_STATUS[1], group: "FreshDesk Status" },
  { name: "Scheduled", filter: FRESH_STATUS[2], group: "FreshDesk Status" },
  { name: "Gas", filter: COPPER_ENERGY_TYPES[3], group: "CopperLabs" },
  { name: "Electric", filter: COPPER_ENERGY_TYPES[0], group: "CopperLabs" },
  {
    name: "Outdoor Water",
    filter: COPPER_ENERGY_TYPES[1],
    group: "CopperLabs",
  },
  { name: "Indoor Water", filter: COPPER_ENERGY_TYPES[2], group: "CopperLabs" },
  { name: "2 Gateways", filter: "Gateways", group: "CopperLabs" },
];

const groupedFilterProperties = groupByKey(filterPossibilities, "group");

const HomesWidget = (props) => {
  const { homes, dates } = props;
  const [widgetHomes, setWidgetHomes] = useState(homes ? [...homes] : []);

  const [searchText, setSearchText] = useState("");
  const [homeFilters, setHomeFilters] = useState([]);
  const [filterLabels, setFilterLabels] = useState([]);

  const [filtered, setFiltered] = useState([]);

  const [dialogKeepMounted, setDialogMount] = useState(false);

  const [showCardDetailsDialog, setCardDetailsDialog] = useState(false);
  const [homeDetails, setHomeDetails] = useState();

  const [showSummary, setShowSummary] = useState(true);

  const options = [];
  //weird work required to make the filter options selection built as a flat list of objects
  // in a way so the select can interact with the options properly
  for (let k = 0; k < groupedFilterProperties.length; k += 1) {
    options.push(
      <ListSubheader key={`subheader-${groupedFilterProperties[k][0].group}`}>
        {groupedFilterProperties[k][0].group}
      </ListSubheader>,
    );
    for (let j = 0; j < groupedFilterProperties[k].length; j += 1) {
      let name = groupedFilterProperties[k][j];
      options.push(
        <MenuItem
          key={name.name}
          value={name}
          selected={filterLabels.some((e) => e.name === name.name)}
        >
          <Checkbox checked={filterLabels.some((e) => e.name === name.name)} />
          {name.name}
        </MenuItem>,
      );
    }
  }

  /*update filtered list if any filter changes*/
  useEffect(() => {
    setFiltered(filterHomes());
  }, [homeFilters, searchText, widgetHomes]);

  /*All filters must match for this home to be returned.
          filters only count if it's been activated for that category.
          check that a match as been found for each required category.  */
  const filtersValid = (colorMatch, copperMatch, bimMatch, freshMatch) => {
    let allFiltersValid = false;

    let colorRequired = false;
    let copperRequired = false;
    let bimRequired = false;
    let freshRequired = false;

    let colorPass = false;
    let copperPass = false;
    let bimPass = false;
    let freshPass = false;

    CALENDAR_COLORS.forEach((item) => {
      //If a color is in filter, make sure there was a color match.
      if (homeFilters.indexOf(item) >= 0) {
        colorRequired = true;
      }
    });

    COPPER_ENERGY_TYPES.forEach((item) => {
      if (homeFilters.indexOf(item) >= 0) {
        copperRequired = true;
      }
    });

    BIM_STATUS.forEach((item) => {
      if (homeFilters.indexOf(item) >= 0) {
        bimRequired = true;
      }
    });

    FRESH_STATUS.forEach((item) => {
      if (homeFilters.indexOf(item) >= 0) {
        freshRequired = true;
      }
    });

    if (!colorRequired) colorPass = true;
    else if (colorMatch) colorPass = true;

    if (!copperRequired) copperPass = true;
    else if (copperMatch) copperPass = true;

    if (!bimRequired) bimPass = true;
    else if (bimMatch) bimPass = true;

    if (!freshRequired) freshPass = true;
    else if (freshMatch) freshPass = true;

    if (colorPass && copperPass && bimPass && freshPass) allFiltersValid = true;

    return allFiltersValid;
  };

  const filtersMatch = (home) => {
    let showHome = false;
    let colorMatch = false;
    let copperMatch = false;
    let bimMatch = false;
    let freshMatch = false;

    let calendarColor = getHomeColor(home);
    let copperConnectedType = getCopperEnergyStatus(home);
    let bimOpenStatus = getBimChecklistStatus(home);
    let freshTicketStatus = getFreshdeskTicketStatus(home);

    if (homeFilters.includes(calendarColor)) colorMatch = true;

    /*Copper check start*/
    let allItemsFound = true;
    let allItemsChecked = false;

    homeFilters.forEach((item) => {
      if (COPPER_ENERGY_TYPES.includes(item)) {
        let itemInFilter = false;
        allItemsChecked = true;

        if (copperConnectedType.includes(item)) itemInFilter = true;

        if (!itemInFilter) allItemsFound = false;
      }
    });

    if (allItemsFound && copperConnectedType.length > 0 && allItemsChecked)
      copperMatch = true;
    /*Copper check end*/

    /*Bim check*/
    let allItemsFound1 = true;
    let allItemsChecked1 = false;

    homeFilters.forEach((item) => {
      if (BIM_STATUS.includes(item)) {
        let itemInFilter = false;
        allItemsChecked1 = true;

        if (bimOpenStatus.includes(item)) itemInFilter = true;

        if (!itemInFilter) allItemsFound1 = false;
      }
    });

    if (allItemsFound1 && bimOpenStatus.length > 0 && allItemsChecked1)
      bimMatch = true;
    /*Bim check end*/

    /*Fresh check*/
    let allItemsFound2 = true;
    let allItemsChecked2 = false;

    homeFilters.forEach((item) => {
      if (FRESH_STATUS.includes(item)) {
        let itemInFilter = false;
        allItemsChecked2 = true;

        if (freshTicketStatus.includes(item)) itemInFilter = true;

        if (!itemInFilter) allItemsFound2 = false;
      }
    });

    if (allItemsFound2 && freshTicketStatus.length > 0 && allItemsChecked2)
      freshMatch = true;
    /*End fresh check*/

    if (filtersValid(colorMatch, copperMatch, bimMatch, freshMatch))
      showHome = true;

    return showHome;
  };

  const filterHomes = () => {
    let filteredHomes = new Array();

    if (widgetHomes) {
      if (searchText == "" || searchText == null) {
        filteredHomes = widgetHomes.filter((home) => filtersMatch(home));
        return filteredHomes;
      } else {
        try {
          filteredHomes = widgetHomes.filter(
            (home) =>
              home.address.toLowerCase().indexOf(searchText.toLowerCase()) >=
                0 && filtersMatch(home),
          );

          return filteredHomes;
        } catch (e) {
          console.log(e);

          return filteredHomes;
        }
      }
    } else return [];
  };
  const resetDialogs = () => {
    setCardDetailsDialog(false);
  };

  var cancelButton = getDialogButton("Cancel", resetDialogs);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleDelete = (chipToRemove) => {
    //Remove chipToRemove from filterLabel and from homeFilters, then set those states
    const newFilters = [...filterLabels];
    const nfIndex = newFilters.indexOf(chipToRemove);

    newFilters.splice(nfIndex, 1);

    const newHomeFilters = [...homeFilters];
    const nhfIndex = newHomeFilters.indexOf(chipToRemove.filter);
    newHomeFilters.splice(nhfIndex, 1);

    setFilterLabels(newFilters);

    setHomeFilters(newHomeFilters);
  };

  const [typedFilter, setTypedFilter] = useState("");

  const userDoneTyping = (theTypedWords) => {
    setSearchText(theTypedWords);
  };

  const [timer, setTimer] = useState(0);

  const userTyping = (event) => {
    setTypedFilter(event.target.value);
    if (timer) {
      clearTimeout(timer);
    }

    setTimer(setTimeout(() => userDoneTyping(event.target.value), 1000));
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilterLabels(value);
    const filt = value.map((item) => item.filter);
    setHomeFilters(filt);
  };

  return (
    <Box sx={{ margin: ".5em", padding: "1em" }}>
      <Grid container direction="column" rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sx={{ display: "flex" }}>
          {!homes && (
            <FetchHomesDateFilter
              sx={{ paddingBottom: "2em" }}
              setHomes={setWidgetHomes}
              dates={dates}
            />
          )}
          <CalendarKey />
        </Grid>
        <Grid item xs={12} sx={{ mb: -2.25 }}>
          <Typography variant="h5">Home Management</Typography>
        </Grid>

        {
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Card>
              <CardContent sx={{ margin: ".5em" }}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  rowSpacing={4.5}
                  columnSpacing={2.75}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      label="Search Homes"
                      inputProps={{
                        value: typedFilter,
                        onChange: userTyping,
                      }}
                      id="search"
                      formcontrolprops={{
                        fullWidth: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    {/*CALENDAR_COLORS = ["Graphite", "Banana", "Tomato", "Tangerine", "Sage", "Basil", "Peacock", "Blueberry", "Lavender", "Flamingo", "Grape"];*/}
                    {filterLabels.map((value) => (
                      <Chip
                        style={{
                          //  marginLeft: ".1em",
                          margin: ".1em",
                          float: "left",
                        }}
                        key={value.name}
                        label={`${value.group} - ${value.name}`}
                        onDelete={() => handleDelete(value)}
                      />
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Select
                      multiple
                      onChange={handleChange}
                      value={filterLabels}
                      input={<OutlinedInput id="select-multiple-chip" />}
                      displayEmpty
                      renderValue={() => "Select Filters"}
                      MenuProps={MenuProps}
                    >
                      {options}
                    </Select>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <Typography variant="h6">
                      {"Filtering: " +
                        filtered.length +
                        "/" +
                        widgetHomes.length}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  style={{
                    marginTop: "20px",
                    maxHeight: "700px",
                    overflow: "auto",
                  }}
                  container
                  direction="row"
                  rowSpacing={4.5}
                  columnSpacing={2.75}
                >
                  {filtered.length > 0 &&
                    filtered.map((home, index) => (
                      <Grid key={index + "_house_id"} item xs={4} sm={4} md={4}>
                        <HomeSummaryCard
                          {...home}
                          showCardDetails={(home) => {
                            setHomeDetails(home);
                            setCardDetailsDialog(true);
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        }
        <DynamicDialog
          buttons={dialogKeepMounted ? null : [cancelButton]}
          open={showCardDetailsDialog}
          title={"Details"}
          children={<HomeDetailsCard {...homeDetails} />}
        />
      </Grid>
    </Box>
  );
};

export default HomesWidget;
