export const BACKEND = process.env.REACT_APP_MAIN_API_URL;

/*Auth*/
export const LOGIN = `${BACKEND}auth/login`;
export const REQUEST_RESET = `${BACKEND}auth/reset`;
export const SUBMIT_RESET_PASSWORD = `${BACKEND}auth/resetpassword`;
export const SUBMIT_RESEND_VERIFY = `${BACKEND}auth/resend`;

export const HEARTBEAT = `${BACKEND}auth/heartbeat`;
export const CATEGORIES = `${BACKEND}desigo/categories`;
export const TEST = `${BACKEND}desigo/printReport`;
export const VERIFY = `${BACKEND}auth/verify`;
export const REGISTER = `${BACKEND}auth/register`;

/*Home*/
export const FETCH_HOMES_SEARCH = `${BACKEND}query/search-homes`;
export const FETCH_HOMES = `${BACKEND}query/homes`;
export const UPLOAD_HOMES = `${BACKEND}query/upload-homes`;
export const VALIDATE_HOMES = `${BACKEND}query/validate-homes`;
export const VALIDATE_HOMES_CLA = `${BACKEND}query/validate-homes-cla`;

export const FETCH_PURE_CALENDAR_EVENTS = `${BACKEND}query/pure-calendar-events`;

export const USER_FETCH = `${BACKEND}user/fetch`;
/*Users*/
export const UPDATE_ACCOUNT = `${BACKEND}user/updateAccount`;
export const DELETE_ACCOUNT = `${BACKEND}user/deleteAccount`;

/*Report*/
export const REPORT_FILTERED_HOMES = `${BACKEND}summary/get-many-home-report`;

/*Backend*/
export const EMAIL_ISSUES = `${BACKEND}backend/mail-builders`;
export const UPDATE_BACKUP_CALENDAR_GUI = `${BACKEND}backend/backup-calendar`;

/*Scheduler*/
export const UPDATE_CX_SCHEDULES = `${BACKEND}query/update-cx-schedules`;

/*Loading Mask Page Ids*/
export const INDEX_MASK_ID = 1;
export const DASHBOARD_MASK_ID = 2;
export const DATE_FILTER_MASK_ID = 3;
export const HELPER_MASK_ID = 4;
export const HOMES_MASK_ID = 5;
export const SCHEDULE_MASK_ID = 6;
export const WIDGET_MASK_ID = 7;
export const ADMIN_MASK_ID = 8;

/*ADMIN*/
export const DOWNLOAD_HOLIG_FILE = `${BACKEND}admin/export-holig`;
export const UPLOAD_BUILDER_CONTACTS = `${BACKEND}admin/upload-builder-contact-list`;
export const SET_AVAILABLE_ALARM_IDS = `${BACKEND}admin/update-available-alarm-ids`;
export const UPLOAD_GEN_3_LOGIN_CHECKLISTS = `${BACKEND}admin/import-gen3-home-login-checklists-from-xml`;
export const DOWNLOAD_XO_FROM_EMAIL = `${BACKEND}admin/download-xo-from-email`;
export const DOWNLOAD_RESALE_FROM_EMAIL = `${BACKEND}admin/download-resale-from-email`;
export const UPDATE_XO_FROM_FILE = `${BACKEND}admin/update-xo-record-from-file-upload`;
export const GENERATE_XO_FROM_DB = `${BACKEND}admin/create-xo-spreadsheet-from-database`;
export const UPLOAD_RESALE_XLSX = `${BACKEND}admin/upload-resale-xlsx`;
export const UPDATE_COMPANY_IDS = `${BACKEND}admin/update-builder-company-ids`;
export const UPDATE_SURVEY_RESULTS = `${BACKEND}admin/update-home-surveys`;
export const UPLOAD_SURVEY_RESULTS = `${BACKEND}admin/upload-mailchimp-results-csv`;
export const MAILCHIMP_WRONG_ADDRESS = `${BACKEND}admin/mailchimp-addresses-not-in-system-csv`;
export const ADMIN_VALIDATE_HOMES = `${BACKEND}admin/validate-homes`;

/*ADMIN REPORTS*/
export const MAKE_XO_MISSING_REPORT = `${BACKEND}admin/make-xo-report`;
export const MAKE_BUILDER_DEFICIENCY_REPORT = `${BACKEND}admin/make-builder-deficency-report`;
export const MAKE_DISCREPANCY_REPORT = `${BACKEND}admin/make-discrepancy-report`;
export const MAKE_OVERALL_BACKLOG_REPORT = `${BACKEND}admin/make-overall-backlog-report`;
export const MAKE_CLA_REPORT = `${BACKEND}admin/make-cla-report`;
export const MAKE_SAMPLE_TECH_REPORT_PDF = `${BACKEND}admin/get-tech-report-sample-pdf`;
export const MAKE_SAMPLE_TECH_REPORT_CSV = `${BACKEND}admin/get-tech-report-sample-csv`;
export const MAKE_SAMPLE_TECH_REPORT_TXT = `${BACKEND}admin/get-tech-report-sample-txt`;
export const GET_TWO_WEEK_TECH_REPORT_BUNDLE_DOWNLOAD = `${BACKEND}admin/get-tech-report-bundle`;

/*Export Reports*/
export const BACKLOG_REMOTE = `${BACKEND}/backend/overall-backlog-remote`;
export const VALIDATE_CLA = `${BACKEND}/backend/validate-cla-remote`;
export const BUILDER_DEFICIENCY = `${BACKEND}/backend/builder-deficiency-remote`;
export const DISCREPANCY = `${BACKEND}/backend/discrepancy-remote`;
