import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import PieChart from "./PieChart";
import { FetchHomesDateFilter } from "components/widgets/fetchHomesDateFilter";
import { Box } from "../../../node_modules/@mui/material/index";
import { useState } from "react";
import { KeyToColor, KeyToHexColor } from "helpers/Service";

const getSeries = (props) => {
  let series = [];
  for (let i = 0; i < 2; i++) {
    let data = [];
    for (let j = props.monthCount - 1; j > -1; j--) {
      Object.keys(props?.closings).forEach((key) => {
        let tech = props.closings[key];
        let yValue = i == 0 ? tech.closing[j] : tech.revisit[j];
        if (tech && tech.title !== undefined)
          yValue ? data.push(yValue) : data.push(0);
      });
    }
    series.push({
      name: i == 0 ? "Closings" : "Revisits",
      data: data,
    });
  }
  return series;
};

const getMonthCategories = (monthCount) => {
  let monthCategories = [moment().format("MMMM YYYY")];

  for (let i = 1; i < monthCount; i++) {
    monthCategories.unshift(moment().subtract(i, "months").format("MMMM YYYY"));
  }
  return monthCategories;
};

const getGroups = (monthCategories, techCount) => {
  let groups = [];

  for (let i = 0; i < monthCategories.length; i++) {
    groups.push({
      title: monthCategories[i],
      cols: techCount,
    });
  }
  return groups;
};

const HomeStatusPieChart = (props) => {
  const theme = useTheme();

  const { homes, homeCategories, dates } = props;
  const [widgetHomes, setWidgetHomes] = useState(homes ? [...homes] : []);

  const series = [];
  const labels = [];
  const colors = [];
  if (widgetHomes) {
    for (let i = 0; i < homeCategories.length; i += 1) {
      series.push(
        widgetHomes.filter(
          (x) =>
            x.srCalendarEvents[0]?.calendarColor ===
            KeyToColor[homeCategories[i]],
        ).length,
      );
      labels.push(homeCategories[i]);
      colors.push(KeyToHexColor[homeCategories[i]]);
    }
  }

  return (
    <Box>
      {!homes && (
        <FetchHomesDateFilter
          sx={{ paddingBottom: "2em" }}
          setHomes={setWidgetHomes}
          dates={dates}
        />
      )}
      {widgetHomes && (
        <PieChart
          series={series}
          labels={labels}
          colors={colors}
          width={500}
          height={400}
        />
      )}
    </Box>
  );
};

export default HomeStatusPieChart;
