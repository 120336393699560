import {
  FETCH_HOMES,
  VALIDATE_HOMES,
  VALIDATE_HOMES_CLA,
  EMAIL_ISSUES,
  UPDATE_CX_SCHEDULES,
  LOGIN,
  USER_FETCH,
  UPDATE_BACKUP_CALENDAR_GUI,
  REQUEST_RESET,
  SUBMIT_RESET_PASSWORD,
  VERIFY,
  REGISTER,
  SUBMIT_RESEND_VERIFY,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  FETCH_PURE_CALENDAR_EVENTS,
  HELPER_MASK_ID,
  FETCH_HOMES_SEARCH,
} from "constants/api";
import { header } from "utils/request";
import moment from "moment";
import { headerWithAuth, login, unlogin } from "utils/auth";
import { updateValidations } from "store/reducers/validation";
import { updatePopup } from "store/reducers/popup";
import { receiveUser } from "store/reducers/user";
import { updateLoading } from "store/reducers/mask";

export const genericErrorMessage = "Generic error occurred : ";

export async function fetchPureCalendarEvents(
  range = [
    moment().subtract(7, "days").format(),
    moment().add(7, "days").format(),
  ],
) {
  var data = {
    startDate: range[0],
    endDate: range[1],
  };

  const result = await fetch(FETCH_PURE_CALENDAR_EVENTS, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        return response.json();
      } //Some error before uploading the files
      else {
        return null;
      }
    })
    .catch((error) => {
      console.error("Error:", error);

      return null;
    });

  return result;
}

export async function fetchHomeDataBySearchString(searchString) {
  var data = {
    searchString: searchString,
  };

  const result = await fetch(FETCH_HOMES_SEARCH, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        return response.json();
      } //Some error before uploading the files
      else {
        return null;
      }
    })
    .catch((error) => {
      console.error("Error:", error);

      return null;
    });

  return result;
}

export async function fetchHomeData(
  range = [
    moment().subtract(7, "days").format(),
    moment().add(7, "days").format(),
  ],
) {
  var data = {
    startDate: range[0],
    endDate: range[1],
  };

  const result = await fetch(FETCH_HOMES, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        return response.json();
      } //Some error before uploading the files
      else {
        return null;
      }
    })
    .catch((error) => {
      console.error("Error:", error);

      return null;
    });

  return result;
}

export async function validateHomes(homes) {
  //console.log("Vaidating Homes...");
  const equipIds = getEquipIds(homes);
  const result = await fetch(VALIDATE_HOMES, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(equipIds),
  })
    .then((response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        return response.json();
      } //Some error before uploading the files
      else {
        return null;
      }
    })
    .catch((error) => {
      console.error("Error validating homes: ", error);

      return null;
    });
  return result;
}
export async function validateHomesCLA(homes) {
  const equipIds = getEquipIds(homes);
  const result = await fetch(VALIDATE_HOMES_CLA, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(equipIds),
  })
    .then((response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        // console.log("validation results: ", response.json());
        return response.json();
      } //Some error before uploading the files
      else {
        return null;
      }
    })
    .catch((error) => {
      console.error("Error validating homes: ", error);

      return null;
    });
  return result;
}

export async function updateBackupCalendar() {
  const AXIOS_TEST_CAL = "";
  const result = await fetch(UPDATE_BACKUP_CALENDAR_GUI, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(AXIOS_TEST_CAL),
  })
    .then((response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        //console.log("email issues results: ", response.json());
        return response.json();
      } //Some error before uploading the files
      else {
        return null;
      }
    })
    .catch((error) => {
      console.error("Error emailing issues: ", error);

      return null;
    });
  return result;
}

export async function emailIssues(homes) {
  const equipIds = getAddressesFromEquipment(homes);
  const result = await fetch(EMAIL_ISSUES, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(equipIds),
  })
    .then((response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        //console.log("email issues results: ", response.json());
        return response.json();
      } //Some error before uploading the files
      else {
        return null;
      }
    })
    .catch((error) => {
      console.error("Error emailing issues: ", error);

      return null;
    });
  return result;
}

export async function updateCxSchedules() {
  const result = await fetch(UPDATE_CX_SCHEDULES, {
    method: "POST",
    headers: headerWithAuth(),
    body: null,
  })
    .then((response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        //console.log(response.json());
      } else {
        console.log(`Error updating schedules: ${response.status}`);
      }
    })
    .catch((error) => {
      console.error("Error updating schedules: ", error);

      return null;
    });
  return result;
}

// Gets all equipment Id's and sends to backend to get most recent valid list of homes
export const getEquipIds = (homes) => {
  let equipIds = [];

  homes.map((item, index) => {
    if (item.bimRecord != null && item.bimRecord.equipment_id != null) {
      equipIds.push(item.bimRecord.equipment_id);
    }
  });
  return equipIds;
};

export const getAddressesFromEquipment = (homes) => {
  let addrs = [];

  homes.map((item, index) => {
    if (item.address != null) {
      addrs.push(item.address);
    }
  });
  return addrs;
};

export async function logoutUser(dispatch) {
  unlogin();

  return true;
}

//This workflow is reused for any standard authentication
export async function updatePassword(data, dispatch, optionalUrl = null) {
  // console.log("Attempting to login", data);
  dispatch(updateLoading({ loading: true, fromId: HELPER_MASK_ID }));

  const result = await fetch(
    optionalUrl ? optionalUrl : SUBMIT_RESET_PASSWORD,
    {
      method: "POST",
      headers: headerWithAuth(),
      body: JSON.stringify(data),
    },
  )
    .then(async (response) => {
      if (response.status == 200) {
        let apiToken = await response.json();
        let userLoginAndFetchSuccessful = true;

        // Update local storage with identical coppy of token data
        login({
          token: apiToken.jwtToken,
          tokenExpirationTime: apiToken.tokenExpirationTime,
          id: apiToken.id,
        });

        // Update reducer with api token data
        let fetchUserSuccess = await fetchUser({ id: apiToken.id }, dispatch);

        if (!fetchUserSuccess) {
          console.log("Login Succeeded, but fetch user failed : ", apiToken);
          userLoginAndFetchSuccessful = false;
        }

        return userLoginAndFetchSuccessful;
      } else if (response.status == 400) {
        //Some error before uploading the files
        let validationObj = await response.json();
        handleValidationErrors(validationObj, "Reset", dispatch);

        return false;
      } else {
        let errorMess = genericErrorMessage;
        errorMess += " check login";

        handleValidationErrors(errorMess, "Reset", dispatch);
        return false;
      }
    })
    .catch((error) => {
      console.error("Error logging in: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check login " + error;

      handleValidationErrors(errorMess, "Reset", dispatch);

      return false;
    })
    .finally(() => {
      dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
    });

  dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
  return result;
}

//This workflow is reused for any standard authentication
export async function requestReset(data, dispatch, optionalUrl = null) {
  // console.log("Attempting to login", data);
  dispatch(updateLoading({ loading: true, fromId: HELPER_MASK_ID }));

  const result = await fetch(optionalUrl ? optionalUrl : REQUEST_RESET, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.status == 200) {
        dispatch(updateValidations([]));
        return true;
      } else if (response.status == 400) {
        //Some error before uploading the files
        let validationObj = await response.json();
        handleValidationErrors(validationObj, "Reset", dispatch);
        return false;
      } else {
        let errorMess = genericErrorMessage;
        errorMess += " check login";

        handleValidationErrors(errorMess, "Reset", dispatch);
        return false;
      }
    })
    .catch((error) => {
      console.error("Error logging in: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check login " + error;

      handleValidationErrors(errorMess, "Reset", dispatch);

      return false;
    })
    .finally(() => {
      dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
    });

  dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
  return result;
}

export async function confirmAccount(data, dispatch, optionalUrl = null) {
  dispatch(updateLoading({ loading: true, fromId: HELPER_MASK_ID }));

  const result = await fetch(optionalUrl ? optionalUrl : VERIFY, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        let apiToken = await response.json();
        let userLoginAndFetchSuccessful = true;

        // Update local storage with identical coppy of token data
        login({
          token: apiToken.jwtToken,
          tokenExpirationTime: apiToken.tokenExpirationTime,
          id: apiToken.id,
        });

        // Update reducer with api token data
        let fetchUserSuccess = await fetchUser({ id: apiToken.id }, dispatch);

        if (!fetchUserSuccess) {
          console.log("Login Succeeded, but fetch user failed : ", apiToken);
          userLoginAndFetchSuccessful = false;
        }

        return userLoginAndFetchSuccessful;
      } else if (response.status == 400) {
        //Some error before uploading the files
        let validationObj = await response.json();
        handleValidationErrors(validationObj, "Reset", dispatch);
        return false;
      } else {
        let errorMess = genericErrorMessage;
        errorMess += " check login";

        handleValidationErrors(errorMess, "Reset", dispatch);
        return false;
      }
    })
    .catch((error) => {
      console.error("Error logging in: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check login " + error;

      handleValidationErrors(errorMess, "Reset", dispatch);

      return false;
    })
    .finally(() => {
      dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
    });

  dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
  return result;
}

//This workflow is reused for any standard authentication
export async function loginUser(data, dispatch, optionalUrl = null) {
  // console.log("Attempting to login", data);
  dispatch(updateLoading({ loading: true, fromId: HELPER_MASK_ID }));

  const result = await fetch(optionalUrl ? optionalUrl : LOGIN, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        let apiToken = await response.json();
        let userLoginAndFetchSuccessful = true;

        // Update local storage with identical coppy of token data
        login({
          token: apiToken.jwtToken,
          tokenExpirationTime: apiToken.tokenExpirationTime,
          id: apiToken.id,
        });

        // Update reducer with api token data
        let fetchUserSuccess = await fetchUser({ id: apiToken.id }, dispatch);

        if (!fetchUserSuccess) {
          console.log("Login Succeeded, but fetch user failed : ", apiToken);
          userLoginAndFetchSuccessful = false;
        }

        return userLoginAndFetchSuccessful;
      } else if (response.status == 400) {
        //Some error before uploading the files
        let validationObj = await response.json();

        handleValidationErrors(validationObj, "Login", dispatch);
        return false;
      } else {
        let errorMess = genericErrorMessage;
        errorMess += " check login";

        handleValidationErrors(errorMess, "Login", dispatch);
        return false;
      }
    })
    .catch((error) => {
      console.error("Error logging in: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check login " + error;

      handleValidationErrors(errorMess, "Login", dispatch);
      dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
      return false;
    });

  dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
  return result;
}

export async function handleValidationErrors(message, from, dispatch) {
  console.log("Error message obj: ", message, from);

  /* If the Object type is a string, then we have no easy way of knowing what object this error belongs to. 
        In this case, just set error to general type and show popup with message.   */
  if (message) {
    if (typeof message === "string") {
      console.log("Generic Error : ", message);
      dispatch(updateValidations({ general: message }));
      dispatch(
        updatePopup({
          title: "Error while attempting to login.",
          popupMessage: message,
          type: "error",
          showPopup: true,
        }),
      );
    } else {
      console.log("Updating error validations : ", message, from);

      if (message.errors) dispatch(updateValidations(message.errors));
      else dispatch(updateValidations(message));
    }
  } else {
    dispatch(updateValidations([{ general: "Unknown error encountered." }]));
  }
  // Just making sure we close the loading mask.  Shouldn't cause a re-render if state is the same.
  dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
}

export async function fetchUser(data, dispatch) {
  const result = await fetch(USER_FETCH, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.status == 200) {
        //Ok may have json data may not.
        let responseData = await response.json();

        // Update reducer with user data
        await dispatch(receiveUser(responseData));

        return true;
      } else if (response.status == 400) {
        //Some error before uploading the files
        let validationObj = await response.json();

        handleValidationErrors(validationObj);
        return false;
      } else {
        let errorMess = genericErrorMessage;
        errorMess += " check user fetch";

        handleValidationErrors(errorMess, "User Fetch", dispatch);
        return false;
      }
    })
    .catch((error) => {
      console.error("Error fetching user: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check user fetch " + error;

      handleValidationErrors(errorMess, "User Fetch", dispatch);
      return false;
    });

  return result;
}

export async function deleteUserAccount(data, dispatch) {
  dispatch(updateLoading({ loading: true, fromId: HELPER_MASK_ID }));
  console.log("here is hte data", data);
  return await fetch(DELETE_ACCOUNT, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.status == 200) {
        dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
        return true;
      } else {
        dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
        return false;
      }
    })
    .catch((error) => {
      console.error("Error deleting user in: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check login " + error;
      dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
      return false;
    });
}

export async function updateAccount(data, dispatch) {
  dispatch(updateLoading({ loading: true, fromId: HELPER_MASK_ID }));

  return await fetch(UPDATE_ACCOUNT, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.status == 200) {
        dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
        return true;
      } else {
        dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
        return false;
      }
    })
    .catch((error) => {
      console.error("Error updating user in: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check login " + error;
      dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
      return false;
    });
}

export async function requestVerification(data, dispatch) {
  // console.log("Attempting to login", data);
  dispatch(updateLoading({ loading: true, fromId: HELPER_MASK_ID }));

  const result = await fetch(SUBMIT_RESEND_VERIFY, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.status == 200) {
        dispatch(updateValidations([]));
        return true;
      } else if (response.status == 400) {
        //Some error before uploading the files
        let validationObj = await response.json();

        handleValidationErrors(validationObj, "Login", dispatch);
        return false;
      } else {
        let errorMess = genericErrorMessage;
        errorMess += " check login";

        handleValidationErrors(errorMess, "Login", dispatch);
        return false;
      }
    })
    .catch((error) => {
      console.error("Error logging in: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check login " + error;

      handleValidationErrors(errorMess, "Login", dispatch);
      dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
      return false;
    });

  dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
  return result;
}

export async function registerUser(data, dispatch) {
  // console.log("Attempting to login", data);
  dispatch(updateLoading({ loading: true, fromId: HELPER_MASK_ID }));

  const result = await fetch(REGISTER, {
    method: "POST",
    headers: headerWithAuth(),
    body: JSON.stringify(data),
  })
    .then(async (response) => {
      if (response.status == 200) {
        dispatch(updateValidations([]));
        return true;
        //Ok may have json data may not.
        /* let apiToken = await response.json();
        let userLoginAndFetchSuccessful = true;

        // Update reducer with api token data
        let fetchUserSuccess = await fetchUser(apiToken.id, dispatch);

        // Update local storage with identical coppy of token data
        if (fetchUserSuccess) {
          login({
            token: apiToken.jwtToken,
            tokenExpirationTime: apiToken.tokenExpirationTime,
            id: apiToken.id,
          });
        } else {
          console.log("Login Succeeded, but fetch user failed : ", apiToken);
          userLoginAndFetchSuccessful = false;
        }

        return userLoginAndFetchSuccessful;*/
      } else if (response.status == 400) {
        //Some error before uploading the files
        let validationObj = await response.json();

        handleValidationErrors(validationObj, "Login", dispatch);
        return false;
      } else {
        let errorMess = genericErrorMessage;
        errorMess += " check login";

        handleValidationErrors(errorMess, "Login", dispatch);
        return false;
      }
    })
    .catch((error) => {
      console.error("Error logging in: ", error);

      let errorMess = genericErrorMessage;
      errorMess += " check login " + error;

      handleValidationErrors(errorMess, "Login", dispatch);
      dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
      return false;
    });

  dispatch(updateLoading({ loading: false, fromId: HELPER_MASK_ID }));
  return result;
}
