import { forwardRef } from "react"; // prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SRInput
import InputRoot from "components/inputs/InputRoot";

const SRInput = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  <InputRoot
    {...rest}
    ref={ref}
    ownerState={{ error, success, disabled }}
    sx={{
      backgroundColor: "#fff",
      marginBottom: '5px'
    }}
  />
));

// Setting default values for the props of SRInput
SRInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the SRInput
SRInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SRInput;
