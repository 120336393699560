import { createSlice } from '@reduxjs/toolkit';


// initial state
const initialState = {
    homes: []    
};

const homes = createSlice({
    name: 'homes',
    initialState,
    reducers: {
        updateHomes(state, action) {
            state.homes = action.payload.homes;            
        }
    }
});

export default homes.reducer;

export const { updateHomes } = homes.actions;
