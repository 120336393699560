import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  validations: [],
};

const validations = createSlice({
  name: "validations",
  initialState,
  reducers: {
    updateValidations(state, action) {
       // console.log('update validations ', action);
      state.validations = action.payload;
    },
  },
});

export default validations.reducer;

export const { updateValidations } = validations.actions;
