import * as React from "react";

import { Grid, Typography, Card, CardContent, Box, Paper } from "@mui/material";

import {
  getShortSummaryFromSrDescription,
  getShortSummaryFromAxiosDescription,
} from "helpers/Service";
import { styled, useTheme, alpha } from "@mui/material/styles";
import moment from "moment";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

import DynamicDialog from "components/dialogs/Dialog";
import { getDialogButton } from "helpers/General";
import { useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "../../../node_modules/@mui/lab/index";
import {
  CalendarMonthTwoTone,
  CheckBoxTwoTone,
  DeveloperBoard,
  DeveloperBoardTwoTone,
  DeviceHubTwoTone,
  ListAltTwoTone,
  Opacity,
  StickyNote2,
} from "../../../node_modules/@mui/icons-material/index";

import {
  Button,
  IconButton,
  Tooltip,
} from "../../../node_modules/@mui/material/index";
import boxShadow from "assets/theme/functions/boxShadow";

const Cell = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
}));

const CellHead = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
  fontWeight: "bold",
}));

const RowTitle = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: 10,
  borderRadius: 0,
  flexGrow: 1,
}));

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const renderDate = (params) => {
  return <div>{params.format("MM-DD-YYYY")}</div>;
};

//OK so this is the full history
//so evvery thing all lumped together and arranged be relevent date
export default function HomeHistoryTimeline(props) {
  const theme = useTheme();

  const [showHtmlDialog, setShowHtmlDialog] = useState(false);
  const [htmlDialog, setHtmlDialog] = useState();

  const resetDialogs = () => {
    setShowHtmlDialog(false);
  };

  var closeButton = getDialogButton("Close", resetDialogs);

  const showCalendarNotes = (event) => {
    setHtmlDialog(event.event.calendarNotes);
    setShowHtmlDialog(true);
  };

  const renderDescription = (event) => {
    if (
      event.eventType === "Truck Roll" ||
      event.eventType === "SR Calendar Closings"
    ) {
      return (
        <div key={event.id}>
          <div
            onClick={() => {
              setHtmlDialog(event.event.calendarNotes);
              setShowHtmlDialog(true);
            }}
          >
            Show Full Description
          </div>

          {event.status}
        </div>
      );
    } else {
      return <div key={event.id}>{event.status}</div>;
    }
  };

  const Columns = [
    {
      field: "date",
      headerName: "Date",
      width: 90,
      renderCell: (params) => renderDate(params),
    },
    { field: "eventType", headerName: "Event Type", width: 150 },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      renderCell: (params) => renderDescription(params),
    },
    { field: "status", headerName: "Status", width: 250 },
  ];

  const { home } = props;

  let eventList = [];

  if (home.axiosCalendarEvents) {
    for (let i = 0; i < home.axiosCalendarEvents.length; i += 1) {
      let event = home.axiosCalendarEvents[i];
      eventList.push({
        id: "axioscal" + event.homeEventId,
        date: new moment(event.closingTime, "YYYY-MM-DDTHH:mm:ss"),
        description: getShortSummaryFromAxiosDescription(event.description),
        tooltip: getShortSummaryFromSrDescription(event.calendarNotes),
        status: event.status,
        event,
        eventType: "Truck Roll",
        color: "success",
        icon: (
          <IconButton sx={{ boxShadow: 3, borderRadius: "4em" }}>
            <CalendarMonthTwoTone sx={{ color: "white" }} />
          </IconButton>
        ),
      });
    }
  }
  if (home.srCalendarEvents) {
    for (let i = 0; i < home.srCalendarEvents.length; i += 1) {
      let event = home.srCalendarEvents[i];
      eventList.push({
        id: "srmanCal" + event.homeEventId,
        date: new moment(event.closingTime, "YYYY-MM-DDTHH:mm:ss"),
        description: getShortSummaryFromSrDescription(event.calendarNotes),
        tooltip: getShortSummaryFromSrDescription(event.calendarNotes),
        event,
        status: event.status,
        eventType: "SR Calendar Closings",
        color: "info",
        icon: (
          <IconButton sx={{ boxShadow: 3, borderRadius: "4em" }}>
            <CalendarMonthTwoTone sx={{ color: "white" }} />
          </IconButton>
        ),
      });
    }
  }
  if (home.bimRecord?.bimChecklists) {
    for (let i = 0; i < home.bimRecord.bimChecklists.length; i += 1) {
      let event = home.bimRecord.bimChecklists[i];
      eventList.push({
        id: "checklist" + event.completed_checklist_id,
        date: new moment(event.updated_at, "YYYY-MM-DDTHH:mm:ss"),
        description: event.name,
        tooltip: `${event.name} status: ${event.description}`,
        status: event.status,

        event,
        eventType: "BIM 360",
        color: "grey",
        icon: <CheckBoxTwoTone sx={{ color: "white" }} />,
      });
    }
  }
  if (home.bimRecord?.bimIssues) {
    for (let i = 0; i < home.bimRecord.bimIssues.length; i += 1) {
      let event = home.bimRecord.bimIssues[i];
      eventList.push({
        id: "issues" + event.issue_id,
        date: new moment(event.updated_at, "YYYY-MM-DDTHH:mm:ss"),
        description: event.description,
        tooltip: event.description,
        status: event.status,
        event,
        eventType: "BIM 360 Issue Status",
        color: "error",
        icon: <ListAltTwoTone sx={{ color: "white" }} />,
      });
    }
  }
  if (home.copperMeters) {
    for (let i = 0; i < home.copperMeters.length; i += 1) {
      let event = home.copperMeters[i];
      eventList.push({
        id: "copper" + event.id,
        date: new moment(event.created_at, "YYYY-MM-DDTHH:mm:ss"),
        description: "Copper meter added " + event.type,
        tooltip: "Copper meter added " + event.type,
        status: event.state,
        event,
        eventType: "CopperLabs Event",
        color: "warning",
        icon: <DeveloperBoardTwoTone sx={{ color: "white" }} />,
      });
    }

    if (home.tickets) {
      for (let i = 0; i < home.tickets.length; i += 1) {
        let event = home.tickets[i];
        eventList.push({
          id: "ticket" + event.id,
          date: new moment(event.created_at, "YYYY-MM-DDTHH:mm:ss"),
          tooltip: event.type,
          description: event.type,
          status: event.subject,
          event,
          eventType: "FreshDesk Ticket",
          color: "primary",
          icon: <StickyNote2 sx={{ color: "white" }} />,
        });
      }
    }

    eventList = eventList
      .filter((a) => !isNaN(a.date.valueOf()))
      .sort(function (a, b) {
        return a.date.diff(b.date);
      });

    return (
      <Box
        sx={{
          width: "100%",

          /*     height: props.height
            ? props.height
            : Math.min(100 + eventList.length * 75, 800),*/
        }}
      >
        <Typography variant="h5" component="div">
          {"Home History Timeline"}
        </Typography>
        <Timeline>
          {eventList.map((event) => (
            <TimelineItem key={event.id}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {renderDate(event.date)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {event.tooltip}
                    </div>
                  }
                >
                  <TimelineDot color={event.color}>
                    <IconButton
                      disabled={
                        event.eventType !== "Truck Roll" &&
                        event.eventType !== "SR Calendar Closings"
                      }
                      onClick={() => showCalendarNotes(event)}
                    >
                      {event.icon}
                    </IconButton>
                  </TimelineDot>
                </Tooltip>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <div>
                  <strong>{event.eventType}</strong>
                  {" :"} {event.status}
                </div>

                <sub>{event.description}</sub>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
        <DynamicDialog
          buttons={[closeButton]}
          open={showHtmlDialog}
          title={"Notes"}
          children={
            <div
              style={{
                border: "1px solid grey",
                maxHeight: "600px",
                overflowY: "auto",
                padding: "1em",
                color: "black",
              }}
              dangerouslySetInnerHTML={{
                __html: htmlDialog,
              }}
            />
          }
        />
      </Box>
    );
  }
}
