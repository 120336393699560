// material-ui
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";

const Logo = () => {
    const theme = useTheme();

    return (
<Grid container direction='row' justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12}>
            <h1>Management Tool</h1>
            </Grid>
            </Grid>
    );
};

export default Logo;
