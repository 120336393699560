// assets
import { ChromeOutlined, QuestionOutlined } from '@ant-design/icons';
import { getPageAccess } from 'helpers/General';

// icons
const icons = {
    ChromeOutlined,
    QuestionOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
    id: 'support',
    title: 'Support',
    type: 'group',
    children: [
        {
            id: 'documentation',
            title: 'Documentation',
            access: getPageAccess(),
            type: 'item',
            url: 'https://axiossoft.com',
            icon: icons.QuestionOutlined,
            external: true,
            target: true
        }
    ]
};

export default support;
