import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

export default function DynamicDialog(props) {
  let { fieldName } = props;

  return (
    <Dialog
      {...props}
      open={props.open}
      keepMounted={props.keepMounted ? true : false}
      aria-labelledby={props.labelId}
      aria-describedby={props.labelId + "desc"}
    >
      {props.loadingMask && props.loadingMask}
      <DialogTitle>
        <div style={{ display: "flex" }}>
          {fieldName ? fieldName + "\t" : ""}
          {props.title}
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography variant="Body1">
          {props.textMessage && <div>{props.textMessage}</div>}
        </Typography>
        {props.form && <div>{props.form}</div>}
        {props.children && <div>{props.children}</div>}
      </DialogContent>
      <DialogActions>
        {props.buttons && (
          <div>
            {props.buttons.map((button, index) => (
              <div
                key={"button" + index}
                style={{ float: "left", marginRight: "10px" }}
              >
                {button}
              </div>
            ))}
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
